import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { notification, message } from 'antd';
import { AppState } from '../../../reducers';

notification.config({
  placement: 'bottomRight',
  bottom: 50,
  duration: 3,
});

class Error extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    //
  }

  public render() {
    let {messages, isError} = this.props;
    if (isError) {
      messages.forEach((message: any) => {
        notification.error({
          message: 'Error',
          description: message,
        });
      });
      // this.props.dispatch({type: 'CLEAR_ERROR'})
    }
    return null;
  }
}

const mapStateToProps = (state: AppState) => ({
  isError: state.error.isError,
  messages: state.error.messages
});

export default connect(mapStateToProps)(Error);

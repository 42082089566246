import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../reducers';
import { showModal } from '../../ProductActions';
import { Modal } from 'antd';
import ProductForm from '../ProductForm/ProductForm';
import { I18n } from 'react-redux-i18n';

class ProductModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { showModal, show_modal, product_types } = this.props;

    return (
      <Modal
        footer = {null}
        title = {I18n.t('product.Products Title')}
        onCancel = {() => showModal(false)}
        visible = {show_modal}
        destroyOnClose = {true}
      >
        <ProductForm product_types={product_types} />
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  show_modal: state.product.show_modal,
  is_mobile: state.auth.is_mobile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ProductModal);

import { getValueByKey } from '../lib/storage';
import { STORAGE_PAYMENTS_FILTERS } from '../types/Constants';
import { GET_PAYMENTS,
  PAYMENTS_RECEIVED,
  CREATE_PAYMENT_RECEIVED,
  UPDATE_PAYMENT_RECEIVED,
  DELETE_PAYMENT_RECEIVED,
} from '../types/PaymentTypes';

export interface PaymentState {
  loading: boolean;
  list_data: any[];
  filter: any[];
  total_count: number;
  totals: any;
}

const defaultState: PaymentState = {
  loading: false,
  list_data: [],
  total_count: 0,
  totals: {
    total: 0,
    sub_total: 0,
  },
  filter: getValueByKey(STORAGE_PAYMENTS_FILTERS, [
    {
      attribute: 'include_count',
      value: true,
    },
    {
      attribute: 'limit',
      value: 10,
    },
  ]),
};

export default (state: PaymentState = defaultState, action: any) => {
 switch (action.type) {
    case GET_PAYMENTS:
      return {...state, loading: true};
    case PAYMENTS_RECEIVED:
      return {...state,
        loading: false,
        list_data: action.data,
        filter: action.filter,
        total_count: action.count,
        totals: action.totals,
      };
    case CREATE_PAYMENT_RECEIVED:
      return {...state, total_count: state.total_count + 1 };
    case UPDATE_PAYMENT_RECEIVED:
      return {...state,
        list_data: state.list_data.map((item: any) => item.id === action.id ? action.data : item),
        totals: action.totals,
      };
    case DELETE_PAYMENT_RECEIVED:
      return {...state,
        list_data: state.list_data.filter((item: any) => item.id !== action.id),
        total_count: state.total_count - 1,
      };
    default:
       return state;
  }
};

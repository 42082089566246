import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  clearData,
} from './SalesOrderActions';
import { AppState } from '../../reducers';
import SalesOrderCreateContent from './components/SalesOrderCreateContent/SalesOrderCreateContent';

class SalesOrderCreate extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentWillMount() {
    this.props.clearData();
  }

  public render() {

    return ( <SalesOrderCreateContent /> );
  }
}

const mapStateToProps = (state: AppState) => ({
  order: state.sales_order.order,
  items: state.sales_order.items,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      clearData,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrderCreate);

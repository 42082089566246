import {
  GET_PURCHASE_ORDERS,
  GET_PURCHASE_ORDER,
  CREATE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER_ITEM,
  CREATE_PURCHASE_ORDER_ITEM,
  DELETE_PURCHASE_ORDER_ITEM,
  // PURCHASE_ORDER_DATA_CREATE,
  PURCHASE_ORDER_CLEAR_DATA,
  PURCHASE_ORDER_PROCESSED,
  SET_SHOWMODAL_PURCHASE_ORDER,
  PURCHASE_ORDER_RECEIVED,
  PURCHASE_ORDER_ITEMS_RECEIVED,
  SET_PURCHASE_ORDER_READING_MODE,
  DELETE_PURCHASE_ORDER,
} from '../../types/PurchaseOrderTypes';
import { GET_STORAGES } from '../../types/StorageTypes';
import { GET_COUNTERPARTIES } from '../../types/CounterpartyTypes';
import { GET_DICTIONARIES } from '../../types/DictionaryTypes';

export const getOrders = (filter: any = []) => ({
  type: GET_PURCHASE_ORDERS,
  filter,
});

export const getOrder = (id: number, filter: any) => ({
  type: GET_PURCHASE_ORDER,
  id,
  filter,
});

export const createOrder = (data: any) => ({
  type: CREATE_PURCHASE_ORDER,
  data,
});

export const updateOrder = (id: number, data: any) => ({
  type: UPDATE_PURCHASE_ORDER,
  data,
  id,
});

export const deleteOrder = (id: number, filter: any) => ({
  type: DELETE_PURCHASE_ORDER,
  id,
  filter,
});

export const updateOrderItem = (id: number, data: any) => ({
  type: UPDATE_PURCHASE_ORDER_ITEM,
  data,
  id,
});

export const createOrderItem = (data: any) => ({
  type: CREATE_PURCHASE_ORDER_ITEM,
  data,
});

export const deleteOrderItem = (id: any, order_id: any) => ({
  type: DELETE_PURCHASE_ORDER_ITEM,
  id,
  order_id,
});

export const clearData = () => ({
  type: PURCHASE_ORDER_CLEAR_DATA,
});

export const setOrderProcessed = (data: boolean, filter: any) => ({
  type: PURCHASE_ORDER_PROCESSED,
  data,
  filter,
});

export const setShowModal = (status: boolean) => ({
  type: SET_SHOWMODAL_PURCHASE_ORDER,
  status,
});

export const clearOrder = () => ({
  type: PURCHASE_ORDER_RECEIVED,
  order: {},
});

export const clearOrderItems = () => ({
  type: PURCHASE_ORDER_ITEMS_RECEIVED,
  items: [],
});

export const setReadingMode = (status: boolean) => ({
  type: SET_PURCHASE_ORDER_READING_MODE,
  status,
});

import { call, takeEvery, take, all } from 'redux-saga/effects';
import actionWatcherTest from './test';
import actionWatcherAuth from './auth';
import actionWatcherTransfer from './transfer';
import actionWatcherProduct from './product';
import actionWatcherPurchaseOrder from './purchase_order';
import actionWatcherTransferOrder from './transfer_order';
import actionWatcherCounterparty from './counterparty';
import actionWatcherStorage from './storage';
import actionWatcherCategories from './category';
import actionWatcherSalesOrder from './sales_order';
import actionWatcherDictionary from './dictionary';
import actionWatcherBill from './bill';
import actionWatcherAccount from './account';
import actionWatcherPayment from './payment';
import actionWatcherDialogProduct from './dialog_product';
import i18nFlowSaga from './i18saga';
import actionWatcherReport from './report';
import actionWatcherStartedData from './started_data';

export default function* rootSaga() {
   yield all([
      actionWatcherAuth(),
      actionWatcherProduct(),
      actionWatcherPurchaseOrder(),
      actionWatcherTransferOrder(),
      actionWatcherCounterparty(),
      actionWatcherStorage(),
      actionWatcherCategories(),
      actionWatcherSalesOrder(),
      actionWatcherDictionary(),
      actionWatcherBill(),
      actionWatcherPayment(),
      actionWatcherAccount(),
      actionWatcherDialogProduct(),
      actionWatcherTransfer(),
      actionWatcherReport(),
      actionWatcherStartedData(),
      i18nFlowSaga(),
   ]);
}

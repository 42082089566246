import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Row } from 'antd';
import SalesOrderForm from '../SalesOrderForm/SalesOrderForm';
import OrderItemsEditable from '../../../components/OrderItemsEditable/OrderItemsEditable';
import {
  createOrder,
  updateOrder,
  createOrderItem,
  deleteOrderItem,
  updateOrderItem,
  clearData,
  getOrder,
} from '../../SalesOrderActions';
import { AppState } from '../../../../reducers';
import SelectField from '../../../components/Fields/SelectField';
import ProductDialog from '../../../components/ProductDialog/ProductDialog';
import { I18n } from 'react-redux-i18n';

class SalesOrderCreateContent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      this.props.getOrder(this.props.match.params.id, []);
    }
  }

  create = (data: any) => {
    if (this.props.order && this.props.order.id) {
      this.props.updateOrder(this.props.order.id, data);
    } else {
      this.props.createOrder(data);
    }
  }

  updateItem = (data: any) => {
    this.props.updateOrderItem(data.id, data);

  }

  deleteItem = (data: any) => {
    this.props.deleteOrderItem(data, this.props.order.id);
  }

  createItem = (data: any) => {
    const {order} = this.props;
    this.props.createOrderItem({...data, s_o_id: order.id});
  }

  renderInput = (props: any) => {
    switch (props.inputType) {
      case 'select':
        let options: any = [];
        if (props.name === 'product_id') {
          options = this.props.products.filter((item: any) => item.published)
            .map((item: any) => ({title: item.title, value: item.id, key: item.id}));
        }
        return (
        <div style = {{ flexDirection : 'row', display : 'flex' }}>
          <div style = {{flex : '1'}}>
            <SelectField
              {...props}
              options = {options}
              handleChange={(e: any) => {
                props.handleChange(e);
                this.props.products.some((item: any) => {
                  if (item.id == e.target.value) {
                    props.handleChange({target: { name : 'price', value : item.price }});
                    return true;
                  }
                });
              }}
            />
          </div>
          <ProductDialog
            onDoubleClick = {(product: any) => {
              props.handleChange({target: { name : 'product_id', value : product.id }});
              props.handleChange({target: { name : 'price', value : product.price }});
            }}
          />
        </div>);
      default:
        return null;
    }
  }

  onTableCell = (col: any, record: any) => {
    switch (col.dataIndex) {
      case 'product_id':
        return {
          inputType: 'select',
          renderInput: this.renderInput,
        };
      default:
        return {
          inputType: 'text',
        };
    }
  }

  public render() {
    const {order, items, isReadingMode} = this.props;
    const validateRules = [
      {name: 'product_id', rules: ['required']},
      {name: 'price', rules: ['required', 'numeric']},
      {name: 'quantity', rules: ['required', 'numeric']},
    ];

    const columns = [
      {
        width: '60%',
        title: I18n.t('sales_order.Product Title'),
        dataIndex: 'product_id',
        editable: true,
        render: (text: any, record: any) => {
          if (record.product) {
            return record.product.title;
          }
          return record.id;
        },
      },
      {
        width: '20%',
        title: I18n.t('sales_order.Price'),
        dataIndex: 'price',
        editable: true,
      },
      {
        width: '20%',
        title: I18n.t('sales_order.Quantity'),
        dataIndex: 'quantity',
        editable: true,
      },
    ];

    return (
      <Row>
        <SalesOrderForm onChange={this.create} order={order}/>
        <div style = {{overflow: 'auto'}}>
          <OrderItemsEditable
            showNewLine = {!isReadingMode && order.id ? true : false }
            showOperations = {!isReadingMode ? true : false }
            items={items}
            create={this.createItem}
            update={this.updateItem}
            delete={this.deleteItem}
            validateRules={validateRules}
            columns={columns}
            onTableCell={this.onTableCell}
            btnCreateShow={false}
            propsTable={{
              pagination: false,
            }}
          />
        </div>
      </Row>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isReadingMode: state.sales_order.isReadingMode,
  order: state.sales_order.order,
  items: state.sales_order.items,
  products: state.started_data.products,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getOrder,
      createOrder,
      updateOrder,
      createOrderItem,
      deleteOrderItem,
      updateOrderItem,
      clearData,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrderCreateContent);

import * as React from 'react';
import { Row } from 'antd';
import LoginForm from './components/LoginForm/LoginForm';

class Login extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    //
  }

  public render() {
    return (
      <Row justify="space-around" align="middle" style={{ height: '100%', width: '100%' }}>
        <LoginForm/>
      </Row>
    );
  }
}

export default Login;

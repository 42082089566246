import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Table, Popconfirm } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { AppState } from '../../../../reducers';
import moment from 'moment';
import findIndex from 'lodash.findindex';
import cloneDeep from 'lodash.clonedeep';
import '../../PurchaseOrder.scss';
import {
  getOrders,
  getOrder,
  setOrderProcessed,
  setShowModal,
  setReadingMode,
  deleteOrder,
} from '../../PurchaseOrderActions';
import { I18n } from 'react-redux-i18n';
import TableColSettings from '../../../components/TableColSettings';

class PurchaseOrderTable extends React.Component<any, any> {

  componentDidMount() {
    if (!localStorage.getItem('p-o-table-col-setting')) {
      localStorage.setItem('p-o-table-col-setting',
        `id,date,counterparty,p_o_type,storage,currency_name,
        currency_rate,base_total,discount,sub_total,total,processed`,
      );
    }
  }

  customExpandIcon(props: any) {
    if (props.expanded) {
        return <a onClick={(e: any) => {
            props.onExpand(props.record, e);
        }}><CaretUpOutlined /></a>;
    } else {
        return <a  onClick={(e: any) => {
            props.onExpand(props.record, e);
        }}><CaretDownOutlined /></a>;
    }
  }

  onChange = (pagination: any, filters: any, sorter: any) => {
    const filter: any = cloneDeep(this.props.filter);
    const index = findIndex(this.props.filter, ['attribute', 'sort']);
    const indexLimit = findIndex(this.props.filter, ['attribute', 'limit']);
    const indexSkip = findIndex(this.props.filter, ['attribute', 'skip']);
    if (indexLimit > -1) {
      const skip = parseFloat(pagination.current) * parseFloat(filter[indexLimit].value) - filter[indexLimit].value;
      if (indexSkip > -1) {
        filter[indexSkip].value = skip;
      } else {
        filter.push({attribute: 'skip', value: skip});
      }
    }

    if (!Object.keys(sorter).length) {
      this.props.getOrders(filter.filter((item: any) => item.attribute !== 'sort'));
      return;
    }

    if (index > -1) {
      filter[index].value = sorter.order === 'ascend' ? sorter.field : '-' + sorter.field;
    } else {
      filter.push({attribute: 'sort', value: sorter.order === 'ascend' ? sorter.field : '-' + sorter.field});
    }
    this.props.getOrders(filter);
  }

  rowClassName = (record: any, index: any) => {
    return record.id === this.props.created_order_id ? 'create-row' : '';
  }

  public render() {
    const {data, filter, total_count, totals, dictionaries, currency} = this.props;

    const tableData: any [] = data.map((item: any) => {
      if (item.processed) {
        let index = -1;
        delete item.row_color;

        if ( findIndex(dictionaries, ['id', parseInt(item.p_o_type, 10)]) > -1) {
          index = findIndex(dictionaries, ['id', parseInt(item.p_o_type, 10)]);
          if (dictionaries[index] && dictionaries[index].style && dictionaries[index].style.color) {
            item.row_color = dictionaries[index].style.color;
          }
        }
        if (!item.row_color && findIndex(currency, ['id', parseInt(item.currency_name, 10)]) > -1) {
          index = findIndex(currency, ['id', parseInt(item.currency_name, 10)]);
          if (currency[index] && currency[index].style && currency[index].style.color) {
            item.row_color = currency[index].style.color;
          }
        }
      } else {
        item.row_color = undefined;
      }
      return item;
    });

    let sortedInfo: any = {};

    let limit = 0;

    filter.forEach((item: any) => {
      if (item.attribute === 'sort') {
        sortedInfo = {
          order: item.value[0] === '-' ? 'descend' : 'ascend',
          columnKey: item.value[0] === '-' ? item.value.slice(1) : item.value,
        };
      }
      if (item.attribute === 'limit') {
        limit = item.value;
      }
    });

    let pagination = {};
    if ( total_count <= limit ) {
      pagination = false;
    } else {
      pagination = {
        total : total_count,
        pageSize: limit,
      };
    }

    const cols: any = (localStorage.getItem('p-o-table-col-setting') || '').split(',');
    const columns: any[] = [];
    const hiddenCols: any = [];

    if (cols.indexOf('id') > -1) {
      columns.push({
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
        render: (item: any, record: any) => {
          return {
            props: { style: { background: record.row_color} },
            children: <div>{item}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({title: 'ID', dataIndex: 'id'});
    }

    if (cols.indexOf('date') > -1) {
      columns.push({
        title: I18n.t('purchase_order.Date'),
        dataIndex: 'date',
        key: 'date',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
        render: (item: any, record: any) => {
          return {
            props: { style: { background: record.row_color} },
            children: <div>{moment(item).format('DD-MM-YYYY')}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({title: I18n.t('purchase_order.Date'), dataIndex: 'date',
        render: (item: any) => moment(item).format('DD-MM-YYYY'),
      });
    }

    if (cols.indexOf('counterparty') > -1) {
      columns.push({
        title: I18n.t('general.Counterparty'),
        dataIndex: 'counterparty',
        key: 'counterparty',
        render: (counterparty: any, record: any) => {
          return {
            props: { style: { background: record.row_color} },
            children: <div>{counterparty ? counterparty.name : ''}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({title: I18n.t('general.Counterparty'), dataIndex: 'counterparty',
        render: (item: any) => item ? item.name : '',
      });
    }

    if (cols.indexOf('p_o_type') > -1) {
      columns.push({
        title: I18n.t('purchase_order.Type'),
        dataIndex: 'p_o_type',
        key: 'p_o_type',
        sorter: true,
        align: 'right',
        sortOrder: sortedInfo.columnKey === 'p_o_type' && sortedInfo.order,
        render: (value: any, record: any) => {
          const index = findIndex(dictionaries, ['id', parseInt(value, 10)]);
          return {
            props: { style: { background: record.row_color} },
            children: <div>{(index > -1) && value && dictionaries[index].short_title}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({title: I18n.t('purchase_order.Type'), dataIndex: 'p_o_type',
        render: (value: any) => {
          const index = findIndex(dictionaries, ['id', parseInt(value, 10)]);
          return (index > -1) && value && dictionaries[index].short_title;
        },
      });
    }

    if (cols.indexOf('storage') > -1) {
      columns.push({
        title: I18n.t('purchase_order.Storage'),
        dataIndex: 'storage',
        key: 'storage',
        render: (storage: any, record: any) => {
          return {
            props: { style: { background: record.row_color} },
            children: <div>{storage ? storage.name : ''}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({title: I18n.t('purchase_order.Storage'), dataIndex: 'storage',
        render: (item: any) => item ? item.name : '',
      });
    }

    if (cols.indexOf('currency_name') > -1) {
      columns.push({
        title: I18n.t('purchase_order.Currency name'),
        dataIndex: 'currency_name',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'currency_name' && sortedInfo.order,
        render: (value: any, record: any) => {
          const index = findIndex(currency, ['id', parseInt(value, 10)]);
          return {
            props: { style: { background: record.row_color} },
            children: <div>{(index > -1) && value && currency[index].short_title}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({title: I18n.t('purchase_order.Currency name'), dataIndex: 'currency_name',
        render: (value: any) => {
          const index = findIndex(currency, ['id', parseInt(value, 10)]);
          return (index > -1) && value && currency[index].short_title;
        },
      });
    }

    if (cols.indexOf('currency_rate') > -1) {
      columns.push({
        title: I18n.t('purchase_order.Currency rate'),
        dataIndex: 'currency_rate',
        key: 'currency_rate',
        sorter: true,
        align: 'right',
        sortOrder: sortedInfo.columnKey === 'currency_rate' && sortedInfo.order,
        render(text: any, record: any) {
          return {
            props: { style: { background: record.row_color} },
            children: <div>{text}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({title: I18n.t('purchase_order.Currency rate'), dataIndex: 'currency_rate'});
    }

    if (cols.indexOf('base_total') > -1) {
      columns.push({
        title: I18n.t('purchase_order.Base total'),
        dataIndex: 'base_total',
        key: 'base_total',
        sorter: true,
        align: 'right',
        sortOrder: sortedInfo.columnKey === 'base_total' && sortedInfo.order,
        render: (baseTotal: any, record: any) => {
          return {
            props: { style: { background: record.row_color} },
            children: <div>{!isNaN(parseFloat(baseTotal)) ? parseFloat(baseTotal).toFixed(2) : baseTotal}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({title: I18n.t('purchase_order.Base total'), dataIndex: 'base_total',
      render: (value: any) => !isNaN(parseFloat(value)) ? parseFloat(value).toFixed(2) : value});
    }

    if (cols.indexOf('discount') > -1) {
      columns.push({
        title: I18n.t('purchase_order.Discount'),
        dataIndex: 'discount',
        key: 'discount',
        sorter: true,
        align: 'right',
        sortOrder: sortedInfo.columnKey === 'discount' && sortedInfo.order,
        render: (discount: any, record: any) => {
          return {
            props: { style: { background: record.row_color} },
            children: <div>
              {!isNaN(parseInt(discount, 10)) ? (parseFloat(discount).toFixed(2) + ' %') : (discount + ' %')}
            </div>,
          };
        },
      });
    } else {
        hiddenCols.push({title: I18n.t('purchase_order.Discount'), dataIndex: 'discount',
        render: (value: any) => (!isNaN(parseInt(value, 10)) ? (parseFloat(value).toFixed(2) + ' %') : (value + ' %')),
      });
    }

    if (cols.indexOf('sub_total') > -1) {
      columns.push({
        title: I18n.t('purchase_order.Sub total'),
        dataIndex: 'sub_total',
        key: 'sub_total',
        sorter: true,
        align: 'right',
        sortOrder: sortedInfo.columnKey === 'sub_total' && sortedInfo.order,
        render: (total: any, record: any) => {
          return {
            props: { style: { background: record.row_color} },
            children: <div>{!isNaN(parseFloat(total)) ?
                parseFloat((total - total / 100 * record.discount).toString()).toFixed(2)
                : (total - total / 100 * record.discount)}
              </div>,
          };
        },
      });
    } else {
      hiddenCols.push({title: I18n.t('purchase_order.Sub total'), dataIndex: 'sub_total',
        render: (total: any, record: any) => (!isNaN(parseFloat(total)) ?
          parseFloat((total - total / 100 * record.discount).toString()).toFixed(2)
          : (total - total / 100 * record.discount)),
      });
    }

    if (cols.indexOf('total') > -1) {
      columns.push({
        title: I18n.t('purchase_order.Total Price'),
        dataIndex: 'total',
        key: 'total',
        sorter: true,
        align: 'right',
        sortOrder: sortedInfo.columnKey === 'total' && sortedInfo.order,
        render: (total: any, record: any) => {
          return {
            props: { style: { background: record.row_color} },
            children: <div>{!isNaN(parseFloat(total)) ?
                parseFloat((total - total / 100 * record.discount).toString()).toFixed(2)
                : (total - total / 100 * record.discount)}
              </div>,
          };
        },
      });
    } else {
      hiddenCols.push({title: I18n.t('purchase_order.Total Price'), dataIndex: 'total',
        render: (total: any, record: any) => (!isNaN(parseFloat(total)) ?
        parseFloat((total - total / 100 * record.discount).toString()).toFixed(2)
        : (total - total / 100 * record.discount)),
      });
    }

    // `id,date,counterparty,p_o_type,storage,currency_name,
    // currency_rate,base_total,discount,sub_total,total,processed`,
    return (
      <div style = {{overflow: 'auto', width: '100%'}} className = "small-table no-editable">
        <TableColSettings
          options = {[
            { label: I18n.t('purchase_order.Id'), value: 'id' },
            { label: I18n.t('purchase_order.Date'), value: 'date' },
            { label: I18n.t('general.Counterparty'), value: 'counterparty' },
            { label: I18n.t('purchase_order.Type'), value: 'p_o_type' },
            { label: I18n.t('purchase_order.Storage'), value: 'storage' },
            { label: I18n.t('purchase_order.Currency name'), value: 'currency_name' },
            { label: I18n.t('purchase_order.Currency rate'), value: 'currency_rate' },
            { label: I18n.t('purchase_order.Base total'), value: 'base_total' },
            { label: I18n.t('purchase_order.Discount'), value: 'discount' },
            { label: I18n.t('purchase_order.Sub total'), value: 'sub_total' },
            { label: I18n.t('purchase_order.Total Price'), value: 'total' },
          ]}
          data = {localStorage.getItem('p-o-table-col-setting')}
          onSave = {(values: any) => {
            this.setState({values});
            localStorage.setItem('p-o-table-col-setting', values);
          }}
        />
        <Table
          columns={columns}
          rowKey={(record: any) => record.id}
          onChange={this.onChange}
          dataSource={tableData}
          bordered={true}
          rowClassName = {this.rowClassName}
          footer={() => {
            return <div>
              {/* <p>Subtotal: {totals.total || '0.00'}</p> */}
              <p>{I18n.t('purchase_order.Summary Total')}: {totals.total || '0.00'}</p>
            </div>;
          }}
          pagination={pagination}
          expandIcon={(props: any) => this.customExpandIcon(props)}
          expandedRowRender= {
            (record: any) => (
            <React.Fragment>
              <div className="hidden-actions">
              {
                record.processed ?
                <React.Fragment>
                  <a onClick = {() =>
                    this.props.setOrderProcessed({id: record.id, status: false}, this.props.filter)}
                  >{I18n.t('purchase_order.Un process')}</a>
                  <a href={'/purchase_order/view/' + record.id}
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.setReadingMode(true);
                      this.props.getOrder(record.id, []);
                      this.props.setShowModal(true);
                  }}>{I18n.t('purchase_order.View')}</a>
                </React.Fragment>
              :
                <React.Fragment>
                  <a onClick = {() =>
                    this.props.setOrderProcessed({id: record.id, status: true}, this.props.filter)}
                  >{I18n.t('purchase_order.Process')}</a>
                  <a href={'/purchase_order/update/' + record.id} onClick={(e) => {
                  e.preventDefault();
                  this.props.setReadingMode(false);
                  this.props.getOrder(record.id, []);
                  this.props.setShowModal(true);
                  // histoty.push('/purchase_order/update/' + record.id);
                }}>{I18n.t('purchase_order.Update')}</a>
                <Popconfirm
                  title= {I18n.t('purchase_order.Sure to delete?')}
                  onConfirm={() => this.props.deleteOrder(record.id, this.props.filter)}
                  cancelText = {I18n.t('purchase_order.Cancel')}
                  okText = {I18n.t('purchase_order.Ok')}
                >
                  <a>{I18n.t('purchase_order.Delete')}</a>
                </Popconfirm>
                </React.Fragment>
              }
              </div>
              {hiddenCols.map((item: any) => <div key={item.dataIndex}>
                {item.title} : {item.render ? item.render(record[item.dataIndex], record) : record[item.dataIndex]}
              </div>)}
          </React.Fragment>)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  data: state.purchase_order.list_data,
  filter: state.purchase_order.filter,
  totals: state.purchase_order.totals,
  dictionaries: state.started_data.dictionaries.filter((item: any) => item.d_type === 'purchase_order'),
  total_count: state.purchase_order.total_count,
  created_order_id: state.purchase_order.created_order_id,
  currency: state.started_data.dictionaries.filter((item: any) => item.d_type === 'currency'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getOrders,
      getOrder,
      setOrderProcessed,
      setShowModal,
      setReadingMode,
      deleteOrder,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderTable);

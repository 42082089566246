export default {
  'Note': 'Нотатки',
  'Date': 'Дата',
  'Intercompany by counterparty': 'Взаєморозрахунок по контрагенту',
  'Account details': 'Звіт по касі',
  'Type': 'Тип ордеру чи накладної',
  'Date from': 'Дата з',
  'Date to': 'Дата по',
  'Account': 'Каса',
  'Accounts': 'Каси',
  'Turnover': 'Оборот',
  'Incoming': 'Прихід',
  'Outcoming': 'Розхід',
  'Counterparty': 'Контрагенти',
  'Counterparties/Accounts': 'Контрагенти/Каси',
  'Remainder to': 'Залишок на %{date}',
  'Remainder': 'Залишок',
  'Product': 'Товар',
  'Count': 'Кількість',
  'Storage': 'Склад',
  'All Categories': 'Всі категорії',
  'Purchase order': 'Прихід',
  'Sales order': 'Розхід',
  'Bill': 'Прихід коштів',
  'Payment': 'Розхід коштів',
  'AvgPrice': 'Сер. ціна',
  'Total': 'Сума',
};

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../reducers';
import { Row } from 'antd';
import moment from 'moment';
import { getReportCounterpartyDetails } from './ReportActions';
import ReportCounterpartyDetailsData from './components/ReportCounterpartyDetailsData';
import { getAllUrlParams } from '../../lib/helper';

class ReportCounterparty extends React.Component<any, any> {
  public dateFrom: any = moment();
  public dateTo: any = moment();
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {

    const params = getAllUrlParams(this.props.location.search);
    this.props.getReportCounterpartyDetails(
      Object.keys(params).map((key: any) => {
        return {attribute: key, value: params[key]};
      }));
  }

  public render() {
    const {filter, counterparties} = this.props;
    return (
      <div>
        <Row>
          <ReportCounterpartyDetailsData/>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  filter: state.report.filter,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getReportCounterpartyDetails,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ReportCounterparty);

import moment from 'moment';
import { put, takeLatest } from 'redux-saga/effects';
import restClient from '../config/rest-client';
import { ERROR } from '../types/ErrorTypes';
import {
  GET_PURCHASE_ORDERS,
  PURCHASE_ORDERS_RECEIVED,
  SET_PURCHASE_ORDERS_FILTER,
  PURCHASE_ORDER_RECEIVED,
  GET_PURCHASE_ORDER,
  CREATE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
  CREATE_PURCHASE_ORDER_ITEM,
  UPDATE_PURCHASE_ORDER_ITEM,
  DELETE_PURCHASE_ORDER_ITEM,
  UPDATE_PURCHASE_ORDER_ITEM_RECEIVED,
  CREATE_PURCHASE_ORDER_ITEM_RECEIVED,
  DELETE_PURCHASE_ORDER_ITEM_RECEIVED,
  PURCHASE_ORDER_ITEMS_RECEIVED,
  PURCHASE_ORDER_PROCESSED,
  PURCHASE_ORDER_PROCESSED_RECEIVED,
  PURCHASE_ORDER_UPDATE,
  PURCHASE_ORDER_SET_TOTALS,
  CREATE_PURCHASE_ORDER_RECEIVED,
  UPDATE_PURCHASE_ORDER_RECEIVED,
  DELETE_PURCHASE_ORDER,
} from '../types/PurchaseOrderTypes';
import generateFilter from '../lib/genFilter';
import findIndex from 'lodash.findindex';
import { GenericEntitiesResponse, GenericEntityResponse } from '../types/baseTypes';
import { setValueByKey } from '../lib/storage';
import { STORAGE_PURCHASE_ORDERS_FILTERS } from '../types/Constants';

function* getOrders({filter}: any) {
  setValueByKey(STORAGE_PURCHASE_ORDERS_FILTERS, filter);
  try {
    const {data, count}: GenericEntitiesResponse = yield restClient.get('purchase_orders', '', generateFilter(filter));
    const ordersTotals: GenericEntityResponse = yield restClient.get('purchase_orders', 'sum_totals', generateFilter(filter, true));
    if (data.length) {
      const totals: GenericEntityResponse[] = yield restClient.get('purchase_orders', 'totals', {
        ids: data.map((item: any) => item.id).join(','),
      });
      data.forEach((item: any) => {
        const index = findIndex(totals, ['p_o_id', item.id]);
        if (index > -1) {
          item.base_total = totals[index].total;
          item.sub_total = totals[index].total;
          item.total = totals[index].total * item.currency_rate;
        } else {
          item.base_total = 0;
          item.total = 0;
          item.sub_total = 0;
        }
      });
    }

    yield put({ type: PURCHASE_ORDERS_RECEIVED, data, count, totals: ordersTotals });
    yield put({ type: SET_PURCHASE_ORDERS_FILTER, filter });
  } catch (error) {
    yield put({ type: PURCHASE_ORDERS_RECEIVED, data: [] , count: 0, totals: {total: 0} });
    yield put({ type: ERROR, messages: ['Not orders']});
  }
}

function* deleteOrder({filter, id}: any) {

  try {

    yield restClient.delete('purchase_orders', id);
    const {data, count}: GenericEntitiesResponse = yield restClient.get('purchase_orders', '', generateFilter(filter));
    const ordersTotals: GenericEntityResponse = yield restClient.get('purchase_orders', 'sum_totals', generateFilter(filter, true));
    if (data.length) {
      const totals: GenericEntityResponse[] = yield restClient.get('purchase_orders', 'totals', {
        ids: data.map((item: any) => item.id).join(','),
      });
      data.forEach((item: any) => {
        const index = findIndex(totals, ['p_o_id', item.id]);
        if (index > -1) {
          item.base_total = totals[index].total;
          item.sub_total = totals[index].total;
          item.total = totals[index].total * item.currency_rate;
        } else {
          item.base_total = 0;
          item.total = 0;
          item.sub_total = 0;
        }
      });
    }

    yield put({ type: PURCHASE_ORDERS_RECEIVED, data, count, totals: ordersTotals });
    yield put({ type: SET_PURCHASE_ORDERS_FILTER, filter });
  } catch (error) {
    yield put({ type: PURCHASE_ORDERS_RECEIVED, data: [] , count: 0, totals: {total: 0} });
    yield put({ type: ERROR, messages: ['Not orders']});
  }
}

function* getOrder({ id }: any) {

  try {
    const data: GenericEntityResponse = yield restClient.findOne('purchase_orders', id, { populate: 'items' });
    const { data: purchaseOrderItems }: GenericEntitiesResponse = yield restClient.find(
      'purchase_order_items',
      {
        p_o_id: id,
        populate: 'product',
        // TODO: improve pagination order items pagination
        limit: 1000,
      },
    );
    yield put({ type: PURCHASE_ORDER_RECEIVED, order: data });
    yield put({ type: PURCHASE_ORDER_ITEMS_RECEIVED, items: purchaseOrderItems });
  } catch (error) {
    yield put({ type: PURCHASE_ORDER_RECEIVED, order: {}});
    yield put({ type: PURCHASE_ORDER_ITEMS_RECEIVED, items: [] });

    yield put({ type: ERROR, messages: ['Not order']});
  }
}

function* createOrder({data}: any) {
  try {
    if (moment.isMoment(data.date)) {
      data.date = data.date.format('YYYY-MM-DD');
    }
    const item: GenericEntityResponse = yield restClient.post('purchase_orders', data);
    const result: GenericEntityResponse = yield restClient.findOne('purchase_orders', item.id, { populate: 'storage,counterparty' });
    yield put({ type: CREATE_PURCHASE_ORDER_RECEIVED, order: result });
  } catch (error) {
    yield put({ type: CREATE_PURCHASE_ORDER_RECEIVED, order: {} });
    yield put({ type: ERROR, messages: ['Not order create']});
  }
}

function* updateOrder({data, id}: any) {
  try {
    if (moment.isMoment(data.date)) {
      data.date = data.date.format('YYYY-MM-DD');
    }
    const item: GenericEntityResponse = yield restClient.update('purchase_orders', id, data);
    const result: GenericEntityResponse = yield restClient.findOne('purchase_orders', item.id, { populate: 'storage,counterparty' });
    const totals: GenericEntityResponse = yield restClient.get('purchase_orders', 'totals', { ids: id });
    yield put({ type: UPDATE_PURCHASE_ORDER_RECEIVED, order: result, id });
    if (totals[0]) {
      yield put({ type: PURCHASE_ORDER_UPDATE, item: {
        base_total: totals[0].total || 0,
        sub_total: totals[0].total || 0,
        total: totals[0].total * item.currency_rate,
        id,
      }});
    }
  } catch (error) {
    yield put({ type: UPDATE_PURCHASE_ORDER_RECEIVED, order: {} });
    yield put({ type: ERROR, messages: ['Not order update']});
  }
}

function* setOrderProcessed({data, filter}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.update('purchase_orders', 'processed', data);
    const ordersTotals: GenericEntityResponse = yield restClient.get('purchase_orders', 'sum_totals', generateFilter(filter, true));
    yield put({ type: PURCHASE_ORDER_PROCESSED_RECEIVED, order: result });
    yield put({ type: PURCHASE_ORDER_SET_TOTALS, totals: ordersTotals });

  } catch (error) {
    yield put({ type: ERROR, messages: ['Not order create']});
  }
}

function* updateOrderItem({data, id}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.update('purchase_order_items', id, data);
    const product: GenericEntityResponse = yield restClient.findOne('products', data.product_id);
    const totals: GenericEntityResponse[] = yield restClient.get('purchase_orders', 'totals', { ids: data.p_o_id });
    const order: GenericEntityResponse = yield restClient.get('purchase_orders', data.p_o_id, { populate: 'items' });
    yield put({ type: PURCHASE_ORDER_UPDATE, item: {
      base_total: totals[0].total || 0,
      sub_total: totals[0].total || 0,
      total: totals[0].total * order.currency_rate,
      id: data.p_o_id,
    }});
    yield put({ type: UPDATE_PURCHASE_ORDER_ITEM_RECEIVED, item: {...result, product}, id });
  } catch (error) {
    // yield put({ type: PURCHASE_ORDER_RECEIVED, order: {} });
    yield put({ type: ERROR, messages: ['Not order item update']});
  }
}

function* createOrderItem({data}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.create('purchase_order_items', data);
    const product: GenericEntityResponse = yield restClient.findOne('products', data.product_id);
    const totals: GenericEntityResponse[] = yield restClient.get('purchase_orders', 'totals', {ids: data.p_o_id});
    const order: GenericEntityResponse = yield restClient.get('purchase_orders', data.p_o_id, { populate: 'items' });
    yield put({ type: PURCHASE_ORDER_UPDATE, item: {
      base_total: totals[0].total || 0,
      sub_total: totals[0].total || 0,
      total: totals[0].total * order.currency_rate,
      id: data.p_o_id,
    }});
    yield put({ type: CREATE_PURCHASE_ORDER_ITEM_RECEIVED, item: {...result, product} });
  } catch (error) {
    // yield put({ type: PURCHASE_ORDER_RECEIVED, order: {} });
    yield put({ type: ERROR, messages: ['Not order item create']});
  }
}

function* deleteOrderItem({id, order_id}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.delete('purchase_order_items', id);
    const totals: GenericEntityResponse[] = yield restClient.get('purchase_orders', 'totals', { ids: order_id });
    const order: GenericEntityResponse = yield restClient.get('purchase_orders', order_id, { populate: 'items' });
    yield put({ type: PURCHASE_ORDER_UPDATE, item: {
      base_total: totals[0].total || 0,
      sub_total: totals[0].total || 0,
      total: totals[0].total * order.currency_rate,
      id: order_id,
    }});
    yield put({ type: DELETE_PURCHASE_ORDER_ITEM_RECEIVED, id });
  } catch (error) {
    // yield put({ type: PURCHASE_ORDER_RECEIVED, order: {} });
    yield put({ type: ERROR, messages: ['Not delete order item']});
  }
}

export default function* actionWatcherPurchaseOrder() {
  yield takeLatest(GET_PURCHASE_ORDERS, getOrders);
  yield takeLatest(GET_PURCHASE_ORDER, getOrder);
  yield takeLatest(CREATE_PURCHASE_ORDER, createOrder);
  yield takeLatest(UPDATE_PURCHASE_ORDER, updateOrder);
  yield takeLatest(CREATE_PURCHASE_ORDER_ITEM, createOrderItem);
  yield takeLatest(UPDATE_PURCHASE_ORDER_ITEM, updateOrderItem);
  yield takeLatest(DELETE_PURCHASE_ORDER_ITEM, deleteOrderItem);
  yield takeLatest(PURCHASE_ORDER_PROCESSED, setOrderProcessed);
  yield takeLatest(DELETE_PURCHASE_ORDER, deleteOrder);
}

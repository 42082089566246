import * as React from 'react';
import { Form, Switch } from 'antd';

class SwitchField extends React.Component<any, any> {
  static defaultProps = {
    errors: {},
    touched: {},
    name: 'defualt',
    values: {},
  };
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    //
  }

  public render() {
    const {
      errors,
      touched,
      handleBlur,
      handleChange,
      inputProps,
      title,
      placeholder,
      isSubmit,
      name,
      values,
    } = this.props;
    return (
      <Form.Item
        { ...errors[name]
          && (touched[name] || isSubmit)
          && { help : errors[name], validateStatus: 'error'}}
      >
       <Switch
        {...inputProps}
        checked={values[name]}
        onChange={(value) => {
          handleChange({target: {
            name,
            value,
          }});
       }}/>
      </Form.Item>
    );
  }
}

export default SwitchField;

import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Spin } from 'antd';
import { AppState } from '../../reducers';
import AccountList from './AccountList';

class Account extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    const {loading} = this.props;

    return (
      <Spin spinning={ loading }>
        <Switch>
          <Route exact path="/account/list" component={AccountList}/>
        </Switch>
      </Spin>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  loading: state.account.loading,
});

export default connect(mapStateToProps)(Account);

import * as React from 'react';
import { Input, Button, Form } from 'antd';
import CategoryTreeSelect from '../CategoryTreeSelect/CategoryTreeSelect';
import { createNewTreeData } from '../../../../lib/helper';
import { I18n } from 'react-redux-i18n';

class CategoryFormContent extends React.Component<any, any> {
  isSubmit: boolean;
  constructor(props: any) {
    super(props);
    this.isSubmit = false;
  }

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleSubmit,
      isSubmitting,
      loading,
      categories,
    }: any = this.props;

    if (isSubmitting) {
      this.isSubmit = true;
    }

    const treeData: any =  [
      { value : 0, title: I18n.t('product.All Categories'), key: '0', children : createNewTreeData(categories) }
    ];

    return (
      <Form layout="vertical">
        <Form.Item label={I18n.t('category.Parent Category')} >
          <CategoryTreeSelect
            treeData={treeData}
            value = { values.parent_id  }
            onSelect = {(value: any) => {
              this.props.handleChange({
                target: {
                  name: 'parent_id',
                  value,
              }});
            }}
          />
        </Form.Item>
        <Form.Item label = {I18n.t('category.Name')}
         { ...errors.name && (touched.name ||  this.isSubmit) && { help : errors.name, validateStatus: 'error'}}
         >
          <Input
            placeholder = {I18n.t('category.Name')}
            onChange = {handleChange}
            name = "name"
            value = {values.name}
          />
        </Form.Item>
        <Form.Item>
          <Button onClick={handleSubmit} type="primary" loading={loading}> {I18n.t('category.Submit')} </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default (CategoryFormContent);

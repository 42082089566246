import {
  GET_REPORT_ACCOUNT,
  CLEAR_REPORT,
  GET_REPORT_COUNTERPARTY,
  GET_REPORT_COUNTERPARTY_DETAILS,
  GET_REPORT_STORAGE,
  GET_REPORT_ACCOUNT_DETAILS,
 } from '../../types/ReportTypes';

export const clearReport = () => ({
  type: CLEAR_REPORT,
});

export const getReportAccount = (filter: any = []) => ({
  type: GET_REPORT_ACCOUNT,
  filter,
});

export const getReportCounterparty = (filter: any = []) => ({
  type: GET_REPORT_COUNTERPARTY,
  filter,
});

export const getReportCounterpartyDetails = (filter: any = []) => ({
  type: GET_REPORT_COUNTERPARTY_DETAILS,
  filter,
});

export const getReportAccountDetails = (filter: any = []) => ({
  type: GET_REPORT_ACCOUNT_DETAILS,
  filter,
});

export const getReportStorage = (filter: any = []) => ({
  type: GET_REPORT_STORAGE,
  filter,
});

import * as React from 'react';
import { Formik } from 'formik';
import FormContent from './components/form_content';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { login } from '../../LoginActions';
import { AppState } from '../../../../reducers';
import validationFields from '../../../../lib/validate';

class LoginForm extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    //
  }

  public render() {
    const {loading} = this.props;
    return (
        <Formik
          initialValues={{ email: '', password: '' }}
          validate={(values: any) => {
            const fields: any = [
              {name: 'email', rules: ['required', 'email']},
            ];
            return validationFields(fields, values);
          }}
          onSubmit={(values, { setSubmitting }) => {
            this.props.login(values);
            setSubmitting(loading);
          }}
          // render={props => ()}
        >
          {(props) =>  <FormContent {...props}/>}
        </Formik>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  logined: state.auth.logined,
  loading: state.auth.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      login,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps) (LoginForm);

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Route, Switch, Redirect } from 'react-router';
import { Spin } from 'antd';
import { AppState } from '../../reducers';
import PurchaseOrderList from './PurchaseOrderList';
import PurchaseOrderView from './PurchaseOrderView';
import PurchaseOrderCreate from './PurchaseOrderCreate';
import PurchaseOrderUpdate from './PurchaseOrderUpdate';
import './PurchaseOrder.scss';

class PurchaseOrder extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    //
  }

  public render() {
    const {loading, loading_counterparty, loading_storage} = this.props;
    return (
      <Spin spinning={loading || loading_storage || loading_counterparty}>
        <Switch>
          <Route exact path="/purchase_order/list" component={PurchaseOrderList}/>
          <Route exact path="/purchase_order/view/:id" component={PurchaseOrderView}/>
          <Route exact path="/purchase_order/update/:id" component={PurchaseOrderUpdate}/>
          <Route exact path="/purchase_order/create" component={PurchaseOrderCreate}/>
        </Switch>
      </Spin>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  loading: state.purchase_order.loading,
  loading_storage: state.storage.loading,
  loading_counterparty: state.counterparty.loading,
});

export default connect(mapStateToProps)(PurchaseOrder);

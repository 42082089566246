import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getOrder, setReadingMode } from './PurchaseOrderActions';
import { Row, Col, Table, Button } from 'antd';
import { AppState } from '../../reducers';
import PurchaseOrderCreateContent from './components/PurchaseOrderCreateContent/PurchaseOrderCreateContent';
import histoty from '../../lib/histoty';
import { I18n } from 'react-redux-i18n';

class PurchaseOrderView extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      this.props.getOrder(this.props.match.params.id, []);
    }
    this.props.setReadingMode(true);
  }

  public render() {
    return (
      <PurchaseOrderCreateContent />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  items: state.purchase_order.items,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setReadingMode,
      getOrder,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderView);

import * as React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { AppState } from '../../../../../reducers';
import ProductDialogModalContent from './ProductDialogModalContent/ProductDialogModalContent';

class ProductDialogModal extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    public render() {
        const { visible, onCancel, is_mobile } = this.props;

        return (
            <div>
               <Modal
                    mask = {true}
                    maskClosable = {false}
                    visible = {visible}
                    onCancel = {onCancel}
                    footer = {null}
                    width = {is_mobile ? 'auto' : '60%'}
                >
                    <ProductDialogModalContent
                        {...this.props}
                    />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    is_mobile: state.auth.is_mobile,
  });

export default connect(mapStateToProps)(ProductDialogModal);

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import './Home.scss';
import { getNews } from './HomeActions';
import Button from 'antd/lib/button';
import { I18n } from 'react-redux-i18n';

class Home extends React.Component<any, any> {
  constructor(props: any) {
      super(props);
  }

  componentDidMount() {
    // this.props.getNews()
    // this.props.dispatch({ type: 'GET_NEWS' })
  }

  public render() {
    return (
        <div className="home">
          {I18n.t('home')}
        </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getNews,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Home);

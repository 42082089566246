import * as React from 'react';
import { Table, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../reducers';
import { showModal, setCategory, deleteCategory } from '../../CategoryActions';
import { I18n } from 'react-redux-i18n';

interface CategoryTableProps {
  setCategory: any;
  showModal: any;
  deleteCategory: any;
  data: any;
  filtered_data: any;
}

function CategoryTable({ data, deleteCategory, filtered_data, setCategory, showModal }: CategoryTableProps) {
  const columns = [
    {
      key: 'action',
      title: I18n.t('category.Operations'),
      render: (value: any) => (
        <span>
          <a href="javascript:;" onClick={() => {
            setCategory(value);
            showModal(true);
          }}> <EditOutlined /> </a>
          <Popconfirm title= {I18n.t('category.Are You Sure?')} onConfirm={() => deleteCategory(value.id)}>
            <a href="javascript:;"> <DeleteOutlined /> </a>
          </Popconfirm>
        </span>
      ),
    },
    {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
  }, {
    key: 'name',
    title: I18n.t('category.Name'),
    dataIndex: 'name',
  }, {
    key: 'parent_id',
    title: I18n.t('category.Parent Category'),
    dataIndex: 'parent_id',
    render: ((parentId: any) => {
      return data.map((item: any) => {
        return item.id === parentId ? item.name : '';
      });
    } ),
  }];

  return (
    <div className="small-table no-status">
      <Table
        bordered
        rowKey="id"
        columns={columns}
        dataSource={filtered_data}
        pagination={{
          defaultPageSize: 20,
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  filtered_data: state.category.filtered_list_data,
  data: state.category.list_data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      deleteCategory,
      showModal,
      setCategory,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTable);

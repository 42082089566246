import { put, takeLatest } from 'redux-saga/effects';
import restClient from '../config/rest-client';
import { GenericEntityResponse } from '../types/baseTypes';

function* login({data}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.post('auth', 'login', data);
    localStorage.setItem('token', result.token);
    restClient.config({
      addHeaders: {
        Authorization: 'Bearer ' + result.token,
      },
    });
    yield put({ type: 'AUTH', logined: true, profile: result.user });
  } catch (error) {
    yield put({ type: 'AUTH', logined: false, profile: {} });
    yield put({ type: 'ERROR', messages: ['Email or Password are invalid']});
  }
}

function* logout() {
    localStorage.removeItem('token');
    restClient.config({
      headers: {
        Authorization: '',
      },
    });

    yield put({ type: 'AUTH', logined: false, profile: {} });
}

function* me() {
  try {

    if (!localStorage.getItem('token')) {
      yield put({ type: 'AUTH', logined: false, profile: {} });
      return;
    }

    const data: GenericEntityResponse = yield restClient.get('auth', 'me', {});
    if (!data) {
      yield put({ type: 'AUTH', logined: false, profile: {} });
    } else {
      yield put({ type: 'AUTH', logined: true, profile: data });
      // yield put({ type: 'GET_DICTIONARIES' });
      // yield put({ type: 'GET_ACCOUNTS' });
    }
  } catch (error) {
    yield put({ type: 'AUTH', logined: false, profile: {} });
  }
  //
}

export default function* actionWatcherAuth() {
  yield takeLatest('LOGIN', login);
  yield takeLatest('ME', me);
  yield takeLatest('LOGOUT', logout);
}

import * as React from 'react';
import { Formik } from 'formik';
import FormContent from './FormContent';
import cloneDeep from 'lodash.clonedeep';
import findIndex from 'lodash.findindex';
import validationFields from '../../../../../lib/validate';
import { connect } from 'react-redux';

function FilterForm({ initialValues, attributes, showClear, rules, is_mobile, onChange }: any) {
  let initial: any = {};
  if (Array.isArray(initialValues)) {
    initialValues.forEach((item: any) => {
      initial[item.attribute] = item;
    });
  } else {
    initial = cloneDeep(initialValues);
  }

  return (
    <Formik
      initialValues={initial}
      enableReinitialize={true}
      validate={(values: any) => {
        return validationFields(rules, values);
      }}
      onSubmit={(values: any, { setSubmitting }) => {
        const deleteAttributeFilter: any = [];
        attributes.forEach((item: any) => {
          if (!values[item.name]) {
            deleteAttributeFilter.push(item.name);
          }
        });
        const tmpFilter = cloneDeep(initialValues);

        Object.keys(values).forEach((key: any) => {
          if (!values[key]) {
            return;
          }
          const index = findIndex(tmpFilter, ['attribute', key]);
          if (index < 0) {
            tmpFilter.push(values[key]);
          } else {
            const item = tmpFilter[index];
            if (item.comparison === values[key].comparison) {
              tmpFilter[index] = values[key];
            } else {
              tmpFilter.push(values[key]);
            }
          }
        });

        onChange(tmpFilter.filter((item: any) => deleteAttributeFilter.indexOf(item.attribute) < 0));
      }}
      onReset={(values: any) => {
        const deleteAttributeFilter: any = [];
        const tmpFilter = cloneDeep(initialValues);
        attributes.forEach((item: any) => {
          const index = findIndex(tmpFilter, ['attribute', item.name]);
          if (index > -1) {
            deleteAttributeFilter.push(item.name);
          }
        });
        onChange(tmpFilter.filter((item: any) => deleteAttributeFilter.indexOf(item.attribute) < 0));
      }}
    >
      {props => <FormContent {...props} showClear={showClear} is_mobile={is_mobile} attributes={attributes} />}
    </Formik>
  );
}

const mapStateToProps = (state: any) => ({
  is_mobile: state.auth.is_mobile,
});

export default connect(mapStateToProps)(FilterForm);

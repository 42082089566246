import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  clearData,
} from './TransferOrderActions';
import { AppState } from '../../reducers';
import TransferOrderCreateContent from './components/TransferOrderCreateContent/TransferOrderCreateContent';

class TransferOrderCreate extends React.Component<any, any> {

  componentWillMount() {
    this.props.clearData();
  }

  public render() {
    return <TransferOrderCreateContent />;
  }
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      clearData,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(TransferOrderCreate);

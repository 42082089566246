import * as React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'antd';
import { AppState } from '../../reducers';
import { bindActionCreators, Dispatch } from 'redux';
import CategoryTree from './components/CategoryTree/CategoryTree';
import CategoryTable from './components/CategoryTable/CategoryTable';
import CategoryModal from './components/CategoryModal/CategoryModal';
import {getCategories, setSelectedCategoryId, setCategory, getFilteredCategories, showModal} from './CategoryActions';
import { createNewTreeData } from '../../lib/helper';
import { I18n } from 'react-redux-i18n';
import CategoryTreeSelect from './components/CategoryTreeSelect/CategoryTreeSelect';

class CategoryList extends React.Component<any, any> {

  componentDidMount() {
    this.props.setSelectedCategoryId(0);
    this.props.getCategories();
  }

  public render() {
    const { is_mobile, data } = this.props;

    const treeData: any =  [
      { value : 0, title: I18n.t('product.All Categories'), key: '0', children : createNewTreeData(data) }
    ];

    return (
      <div>
        <CategoryModal/>
        <Row>
          <Col md={5} sm= {23} xs = {24} style = {{overflow: 'auto'}}>

          { is_mobile ?
            <CategoryTreeSelect
              value={this.props.selectedKey}
              onSelect={(id: number) => this.props.setSelectedCategoryId(id)}
              treeData={treeData}
            />
            :
            <CategoryTree
              selectedKeys={this.props.selectedKey}
              treeData={treeData}
              onSelect={(id: string) => {
                if (id[0]) {
                  this.props.setSelectedCategoryId(parseInt(id, 10));
                }
              }}
            />}
          </Col>
          <Col md={19} sm= {23} xs = {24} >
            <Button
              type="primary"
              style={{ margin : '16px 0' }}
              onClick={ () => {
                this.props.setCategory({});
                this.props.showModal(true);
              }}
            > {I18n.t('category.Create')} </Button>
            <div style = {{overflow: 'auto'}}>
              <CategoryTable />
            </div>
          </Col>
        </Row>
    </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  data: state.category.list_data,
  selectedKey: state.category.selectedCategoryId,
  is_mobile: state.auth.is_mobile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCategories,
      getFilteredCategories,
      setSelectedCategoryId,
      showModal,
      setCategory,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);

import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import ProductDialogTree from '../../ProductDialogTree/ProductDialogTree';
import ProductDialogTable from '../../ProductDialogTable/ProductDialogTable';
import { AppState } from '../../../../../../reducers';
import { getDialogProducts } from '../../../ProductDialogActions';
import Filter from '../../../../Filter/Filter';
import { I18n } from 'react-redux-i18n';
import ProductDialogSelect from '../../ProductDialogSelect/ProductDialogSelect';

class ProductDialogModalContent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    onChangeFilter = (filter: any) => {
        this.props.getDialogProducts(filter);
    }

    public render() {
        const { onDoubleClick, filter, is_mobile } = this.props;

        const isSelectedCategory = filter.find((item: any) => item.attribute === 'category_id');

        return (
            <div style = {{overflow: 'auto'}}>
                <Row>
                    <Col span = {8} xs = {24}>
                        <Row className='row-filter-container' style = {{paddingTop : '30px'}}>
                        <Filter
                            onChange = {this.onChangeFilter}
                            initialValues={filter}
                            attributes={[
                                {
                                    name: 'title',
                                    title: I18n.t('product_dialog.Title'),
                                    hyper_type: 'input',
                                    comparison: 'contains',
                                },
                            ]}
                        />
                        </Row>
                        <Row style = {{paddingTop : '15px' }}>
                            <Col md = {6} xs = {24} sm = {24}>
                                {is_mobile ? <ProductDialogSelect /> : <ProductDialogTree/>}
                            </Col>
                            <Col md = {16} xs = {24} sm = {24} style = {{paddingTop : '30px', overflow: 'auto'}}>
                                <ProductDialogTable
                                    categoryNotSelected = {!isSelectedCategory}
                                    onDoubleClick = {onDoubleClick}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    filter: state.dialog_product.filter,
    is_mobile: state.auth.is_mobile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators ({
        getDialogProducts,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ProductDialogModalContent);

import * as React from 'react';
import { Layout, Menu } from 'antd';
import { LaptopOutlined, LogoutOutlined } from '@ant-design/icons';
import SubMenu from "antd/lib/menu/SubMenu";
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { AppState } from '../../../../../reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { logout } from '../../../LogoutActions';

const { Sider } = Layout;

class SideBar extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      defaultOpen: '',
    };
  }

  componentDidMount() {
    //
  }
  selectMenuItem = () => {
    const res = window.location.pathname.split('/');
    if (res[1]) {
      if (res[1] !== 'report') {
        return res[1];
      } else {
        return res[1] + 's_' + res[2];
      }
    }
    return '';
  }

  componentWillMount() {
    const res = window.location.pathname.split('/');
    switch (res[1]) {
      case '':
        this.setState({defaultOpen: ''});
        return;
      case 'dictionary':
      case 'account':
      case 'counterparty':
      case 'category':
      case 'product':
      case 'storage':
      case 'default_value':
        this.setState({defaultOpen: 'directories'});
        return;

      case 'bill':
      case 'payment':
      case 'transfer':
        this.setState({defaultOpen: 'money'});
        return;

      case 'purchase_order':
      case 'sales_order':
        this.setState({defaultOpen: 'orders'});
        return;

      case 'report':
        this.setState({defaultOpen: 'reports'});
        return;
      default: return '';
    }
  }

  public render() {
    const {isMobile, collapsed, hideMenu} = this.props;
    const selectedKey: string = this.selectMenuItem();

    return (
      <Sider
        style={{
          background: '#fff',
          minHeight: '100vh',
          overflow: 'auto',
          left: 0,
          // position: 'absolute',
        }}
        trigger={null}
        collapsible
        collapsedWidth = {isMobile ? 0 : 80}
        collapsed={collapsed}
      >
        <Menu
          mode="inline"
          defaultOpenKeys = {[this.state.defaultOpen]}
          selectedKeys = {[selectedKey]}
          style={{ height: '100%', borderRight: 0 , overflowX: 'hidden' }}
        >
          <SubMenu key="directories"
            icon={<LaptopOutlined />}
            title={<span>{I18n.t('layout.Directories')}</span>}
          >
            <Menu.Item key="dictionary" onClick = {hideMenu}>
              <Link to="/dictionary/list">{I18n.t('layout.Dictionaries')}</Link>
            </Menu.Item>
            <Menu.Item key="account" onClick = {hideMenu}>
              <Link to="/account/list">{I18n.t('layout.Accounts')}</Link></Menu.Item>
            <Menu.Item key="counterparty" onClick = {hideMenu}>
              <Link to="/counterparty/list">{I18n.t('layout.Counterparties')}</Link>
            </Menu.Item>
            <Menu.Item key="category" onClick = {hideMenu}>
              <Link to="/category/list">{I18n.t('layout.Categories')} </Link></Menu.Item>
            <Menu.Item key="product" onClick = {hideMenu}>
              <Link to="/product/list">{I18n.t('layout.Catalog')}</Link> </Menu.Item>
            <Menu.Item key="storage" onClick = {hideMenu}>
              <Link to="/storage/list">{I18n.t('layout.Storages')}</Link> </Menu.Item>
            <Menu.Item key="default_value" onClick = {hideMenu}><Link to="/default_value/list">
              {I18n.t('layout.Default value')}</Link> </Menu.Item>
          </SubMenu>
          <SubMenu key="money" title={<span><LaptopOutlined /><span>{I18n.t('layout.Money')}</span></span>}>
            <Menu.Item key="bill" onClick = {hideMenu}><Link to="/bill/list">{I18n.t('layout.Bill')}</Link></Menu.Item>
            <Menu.Item key="payment" onClick = {hideMenu}>
              <Link to="/payment/list">{I18n.t('layout.Payment')}</Link></Menu.Item>
            <Menu.Item key="transfer" onClick = {hideMenu}>
              <Link to="/transfer/list">{I18n.t('layout.Transfer')}</Link></Menu.Item>
          </SubMenu>
          <SubMenu key="orders"
            title={<span><LaptopOutlined /><span>{I18n.t('layout.Orders')}</span></span>}
          >
            <Menu.Item key="purchase_order" onClick = {hideMenu}>
              <Link to="/purchase_order/list">{I18n.t('layout.Purchase Order')}</Link>
            </Menu.Item>
            <Menu.Item key="sales_order" onClick = {hideMenu}>
              <Link to="/sales_order/list">{I18n.t('layout.Sales Order')}</Link></Menu.Item>
            <Menu.Item key="transfer_order" onClick = {hideMenu}>
              <Link to="/transfer_order/list">{I18n.t('layout.Transfer Order')}</Link></Menu.Item>
          </SubMenu>
          <SubMenu key="reports"
            title={<span><LaptopOutlined /><span>{I18n.t('layout.Reports')}</span></span>}
          >
            <Menu.Item key="reports_account" onClick = {hideMenu}>
              <Link to="/report/account">{I18n.t('layout.Reports account')}</Link>
            </Menu.Item>
            <Menu.Item key="reports_counterparty" onClick = {hideMenu}>
              <Link to="/report/counterparty">{I18n.t('layout.Reports counterparty')}</Link>
            </Menu.Item>
            <Menu.Item key="reports_storage" onClick = {hideMenu}>
              <Link to="/report/storage">{I18n.t('layout.Reports storage')}</Link>
            </Menu.Item>
          </SubMenu>
         
          <Menu.Item key="logout" icon={<LogoutOutlined />}>
            <a onClick={() => {
              this.props.logout();
            }}>{I18n.t('layout.Logout')}</a>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  logined: state.auth.logined,
  loading: state.auth.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      logout,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps) (SideBar);

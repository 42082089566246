import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Row } from 'antd';
import { AppState } from '../../reducers';
import OrderItemsEditable from '../components/OrderItemsEditable/OrderItemsEditable';
import Filter from '../components/Filter/Filter';
import {
  createDictionary,
  updateDictionary,
  deleteDictionary,
  getDictionaries,
} from './DictionaryActions';
import { I18n } from 'react-redux-i18n';
import SelectField from './components/SelectField';
import cloneDeep from 'lodash.clonedeep';
import findIndex from 'lodash.findindex';
import ColorPicker from '../components/ColorPicker/indes';

class DictionaryList extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      selected_type: '',
    };
  }

  componentDidMount() {
    const retrievedObject: any = localStorage.getItem('default_values') || '{}';
    const dictionary_limit: number = JSON.parse(retrievedObject).dictionary_limit;
    const filter: any = cloneDeep(this.props.filter);
    const indexLimit = findIndex(this.props.filter, ['attribute', 'limit']);

    if (indexLimit > -1) {
      filter[indexLimit].value = dictionary_limit || 1000;
    } else {
      filter.push({ attribute: 'limit', value: 1000 });
    }

    this.setState({
      selected_type: (filter.find((item: any) => item.attribute === 'd_type') || { value: '' }).value,
    })

    const indexSkip = findIndex(this.props.filter, ['attribute', 'skip']);
    if (indexSkip > -1) {
      filter[indexSkip].value = 0;
    }

    this.props.getDictionaries(filter);
  }

  onChangeFilter = (filter: any) => {
    this.props.getDictionaries(filter);
    let value = '';
    filter.map((item: any) => {
      value = item.attribute === 'd_type' ? item.value : '';
    });
    this.setState({selected_type : value});
  }

  updateItem = (data: any) => {
    this.props.updateDictionary(data.id, data);
  }

  deleteItem = (data: any) => {
    this.props.deleteDictionary(data);
  }

  createItem = (data: any) => {
    this.props.createDictionary({...data});
  }

  renderInput = (props: any) => {
    switch (props.inputType) {
      case 'select':
        let options: any = [];
        if (props.name === 'd_type') {
          options = this.props.types.map((item: any) => ({title: item, value: item}));
        }
        return <SelectField
          {...props}
          options={options}
        />;
      case 'color_picker' :
          return <ColorPicker  {...props} />;
      default:
        return null;
    }
  }

  onTableCell = (col: any, record: any) => {
    switch (col.dataIndex) {
      case 'd_type':
        return {
          inputType: 'select',
          renderInput: this.renderInput,
        };
      case 'style[color]' :
        return {
          inputType: 'color_picker',
          renderInput: this.renderInput,
        };
      default:
        return {
          inputType: 'text',
        };
    }
  }

  onChange = (pagination: any, filters: any, sorter: any) => {

    const filter: any = cloneDeep(this.props.filter);

    const index = findIndex(this.props.filter, ['attribute', 'sort']);
    const indexLimit = findIndex(this.props.filter, ['attribute', 'limit']);
    const indexSkip = findIndex(this.props.filter, ['attribute', 'skip']);
    if (indexLimit > -1) {
      const skip = parseFloat(pagination.current) * parseFloat(filter[indexLimit].value) - filter[indexLimit].value;
      (indexSkip > -1) ? filter[indexSkip].value = skip : filter.push({attribute: 'skip', value: skip});
    }

    if (!Object.keys(sorter).length) {
      this.props.getDictionaries(filter.filter((item: any) => item.attribute !== 'sort'));
      return;
    }

    if (index > -1) {
      filter[index].value = sorter.order === 'ascend' ? sorter.field : '-' + sorter.field;
    } else {
      filter.push({attribute: 'sort', value: sorter.order === 'ascend' ? sorter.field : '-' + sorter.field});
    }
    this.props.getDictionaries(filter);
  }

  public render() {
    const {dictionaries, filter, total_count, types } = this.props;

    let pagination = {};
    let limit = 0;

    let propsTable = {};
    if ( total_count <= limit ) {
      propsTable = {
        bordered: true,
        pagination : false,
      };
    } else {
      propsTable = {
        bordered: true,
        onChange : (pagin: any, filters: any, sorter: any) => {this.onChange(pagin, filters, sorter); },
        pagination: {
          total : total_count + total_count / limit,
          pageSize: limit + 1,
        },
      };
    }

    filter.forEach((item: any) => {
      if (item.attribute === 'limit') {
        limit = item.value;
      }
    });

    if ( total_count <= limit ) {
      pagination = false;
    } else {
      pagination = {
        total : total_count + total_count / limit,
        pageSize: limit + 1,
      };
    }

    const validateRules = [
      {name: 'title', rules: ['required']},
      {name: 'slug', rules: ['required']},
      {name: 'd_type', rules: ['required']},
    ];

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: I18n.t('dictionary.Title'),
        dataIndex: 'title',
        editable: true,
      },
      {
        title: I18n.t('dictionary.Short title'),
        dataIndex: 'short_title',
        editable: true,
      },
      {
        title: I18n.t('dictionary.Slug'),
        dataIndex: 'slug',
        editable: true,
      },
      {
        title: I18n.t('dictionary.Type'),
        dataIndex: 'd_type',
        editable: true,
      },
      {
        title: I18n.t('dictionary.Color'),
        dataIndex: 'style[color]',
        editable: true,
        render: (item: any) => {
          return <ColorPicker disablePicker = {true} color = {item}/>;
        },
      },
    ];

    return (
      <div className = "small-table simple-actions">
        <Filter
          onChange={this.onChangeFilter}
          initialValues={filter}
          attributes={[
            {
              name: 'd_type',
              title: I18n.t('dictionary.Type'),
              hyper_type: 'select',
              options: types.map((item: any) => {
                return {
                  title: item,
                  value: item,
                };
              }),
            },
          ]}
        />
        <Row style = {{overflow: 'auto', width: '100%'}} >
          <OrderItemsEditable
            onChange = {this.onChange}
            insertNewRow = "first"
            btnCreateDisabled={false}
            items={dictionaries}
            create={this.createItem}
            update={this.updateItem}
            delete={this.deleteItem}
            validateRules={validateRules}
            onTableCell={this.onTableCell}
            columns={columns}
            initionalNewRowValues={{d_type: this.state.selected_type || undefined}}
            showNewLine={true}
            btnCreateShow={false}
            propsTable = {propsTable}
            pagination = {pagination}
            enableReinitialize={true}
            actionUpdateShow={(item: any) => !item.processed}
            actionDeleteShow={(item: any) => !item.processed}
            operationsOnLeft = {true}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  filter: state.dictionary.filter,
  dictionaries: state.dictionary.list_data,
  types: state.dictionary.dictionary_types,
  total_count: state.dictionary.total_count,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createDictionary,
      updateDictionary,
      deleteDictionary,
      getDictionaries,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(DictionaryList);

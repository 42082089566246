import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Route, Switch, Redirect } from 'react-router';
import { Spin } from 'antd';
import { AppState } from '../../reducers';
import ReportAccount from './ReportAccount';
import ReportCounterparty from './ReportCounterparty';
import ReportCounterpartyDetails from './ReportCounterpartyDetails';
import ReportStorage from './ReportStorage';

class Report extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    //
  }

  public render() {
    const {loading} = this.props;
    return (
      <Spin spinning={loading}>
        <Switch>
          <Route exact path="/report/account" component={ReportAccount}/>
          <Route exact path="/report/counterparty" component={ReportCounterparty}/>
          <Route exact path="/report/counterparty_details" component={ReportCounterpartyDetails}/>
          <Route exact path="/report/storage" component={ReportStorage}/>
        </Switch>
      </Spin>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  loading: state.purchase_order.loading,
});

export default connect(mapStateToProps)(Report);

import * as React from 'react';
import ProductDialogModal from './components/ProductDialogModal/ProductDialogModal';
import { Button } from 'antd';

class ProductDialog extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,
        };
    }

    componentDidMount() {
        //
    }

    onDoubleClick = (id: any) => {
        this.setState({showModal : false});
        this.props.onDoubleClick(id);
    }

    public render() {
        return (
            <div>
                <Button
                    style = {{margin: '0 5px'}}
                    onClick = {() => this.setState({showModal : true})}
                > . . . </Button>
                <ProductDialogModal
                    visible = {this.state.showModal}
                    onDoubleClick = { (id: any) => this.onDoubleClick(id)}
                    onCancel = { () => this.setState({showModal : false})}
                />
            </div>
        );
    }
}

export default ProductDialog;

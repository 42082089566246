import * as React from 'react';
import { Input, Form } from 'antd';

class FieldInput extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    //
  }

  public render() {
    const {attribute, values} = this.props;
    let value;
    if (values) {
      value = values.value;
    }

    return (
      <Form.Item
            // validateStatus={'error'}
            // help={'Error'}
          >
        <Input
          placeholder={attribute.title}
          name={attribute.name}
          value = {value}
          allowClear = {true}
          onChange={(e) => {
            const valueAtt: any = {
              attribute: attribute.name,
              value: e.target.value,
            };

            if (attribute.comparison) {
              valueAtt.comparison = attribute.comparison;
            }
            this.props.handleChange({
              target: {
                name: attribute.name,
                value: e.target.value ? valueAtt : '',
              },
            });
            setTimeout(() => {
              this.props.handleSubmit();
            }, 200);
          }}
        />
      </Form.Item>
    );
  }
}

export default FieldInput;

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../reducers';
import { Table } from 'antd';
import _ from 'lodash';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';

class ReportAccountDetailsData extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    //
  }

  public render() {
    const { data, counterparties, accounts, loading } = this.props;

    const columns = [
      {
        title: I18n.t('report.Counterparties/Accounts'),
        key: 'counterparty_id',
        render: (_: any, item: any) => {
          if (item.counterparty_id) {
            return (counterparties.find((counterparty: any) => counterparty.id === item.counterparty_id) || {}).name;
          } else if (item.account_to_id) {
            return I18n.t('report.Account') + ': ' + (accounts.find((account: any) => account.id ===item.account_to_id) || {}).name;
          } else if (item.account_from_id) {
            return I18n.t('report.Account') + ': ' + (accounts.find((account: any) => account.id ===item.account_from_id) || {}).name;
          } else {
            return '';
          }          
        },
      },
      {
        title: I18n.t('report.Date'),
        dataIndex: 'date',
        key: 'date',
        render: (date: any) => {
          return date ? moment(date).format('L') : '';
        },
      },
      {
        title: I18n.t('report.Incoming'),
        render: (row: any) => {
          if (row.type === 'bill' || row.type === 'transfer_to') {
            return row.total.formatNumber();
          }
          return '';
        },
        align: 'right' as any,
        key: 'incoming',
      },
      {
        title: I18n.t('report.Outcoming'),
        render: (row: any) => {
          if (row.type === 'payment' || row.type === 'transfer_from') {
            return row.total.formatNumber();
          }
          return '';
        },
        align: 'right' as any,
        key: 'outcoming',
      },
    ];

    return (
      <div style = {{overflow: 'auto', width: '100%'}}>
        <Table
          rowKey ={(record: any) => record.id}
          columns={columns}
          dataSource={data}
          bordered
          pagination={false}
          loading={loading}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  data: state.report.report_details,
  filter: state.report.filter,
  loading: state.report.loading,
  counterparties: state.started_data.counterparties,
  accounts: state.started_data.accounts,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ReportAccountDetailsData);

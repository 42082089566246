import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../reducers';
import { Table, Modal, Row } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { I18n } from 'react-redux-i18n';
import ReportAccountDetailsData from './ReportAccountDetailsData';
import { getReportAccountDetails } from '../ReportActions';
import Filter from '../../components/Filter/Filter';

class ReportAccountData extends React.Component<any, any> {
  public dateFrom: any = moment().toJSON();
  public dateTo: any = moment().toJSON();
  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    //
  }

  disabledStartDate = (startValue: any, endValue: any) => {
    startValue = moment(startValue);
    endValue = moment(endValue);
    if (!startValue || !endValue) {
      return false;
    }
    return !startValue || startValue.isAfter(endValue);
  }

  disabledEndDate = (startValue: any, endValue: any) => {
    startValue = moment(startValue);
    endValue = moment(endValue);
    if (!endValue || !startValue) {
      return false;
    }
    return !endValue || endValue.isBefore(startValue);
  }

  public render() {
    const {data, filter, accounts, is_mobile, loading} = this.props;

    // tslint:disable-next-line: variable-name
    let date_from; let date_to;

    filter.forEach((item: any) => {
      if (item.attribute === 'date_from') {
        date_from = item.value;
      }
      if (item.attribute === 'date_to') {
        date_to = item.value;
      }
    });

    const renderNumber = (value: number) => (value || 0).formatNumber()

    const columns = [
      {
        title: I18n.t('report.Accounts'),
        dataIndex: 'account_id',
        key: 'account_id',
        render: (accountId: number) => {
          const index = _.findIndex(accounts, ['id', accountId]);
          return index > -1 ? accounts[index].name : accountId;
        },
      },
      {
        title: I18n.t('report.Remainder to', {date: moment(date_from).format('L')}),
        dataIndex: 'remainder',
        key: 'remainder',
        align: 'right' as any,
        render: renderNumber,
      },
      {
        title: I18n.t('report.Turnover'),
        children: [
          {
            title: I18n.t('report.Incoming'),
            dataIndex: 'incoming',
            key: 'incoming',
            align: 'right' as any,
            render: renderNumber,
          },
          {
            title: I18n.t('report.Outcoming'),
            dataIndex: 'outcoming',
            key: 'outcoming',
            align: 'right' as any,
            render: renderNumber,
          },
        ],
      },
      {
        title: I18n.t('report.Remainder to', {date: moment(date_to).format('L')}),
        align: 'right' as any,
        render: (item: any) => {
          return ((item.remainder || 0) + (item.incoming || 0) - (item.outcoming || 0)).formatNumber();
        },
      },
    ];

    return (
      <React.Fragment>
        <Modal
          width={is_mobile ? '100%' : '90%'}
          title={I18n.t('report.Account details')}
          visible={this.state.visible}
          onCancel={() => {this.setState({visible: false})}}
          footer={false}
          // onCancel={this.handleCancel}
        >
          <Row>
            <Filter
              onChange={(values: any) => {
                this.props.getReportAccountDetails([...values,
                  {attribute: 'account_id', value: this.state.account_id},
                ]);
              }}
              rules={[
                {name: 'date_from', rules: ['required']},
                {name: 'date_to', rules: ['required']},
              ]}
              initialValues={filter}
              attributes={[
                {
                  name: 'date_from',
                  title: I18n.t('report.Date from'),
                  label: I18n.t('report.Date from'),
                  hyper_type: 'date',
                  inputProps: {
                    disabledDate: (startValue: any) => {
                      return this.disabledStartDate(startValue, this.dateTo);
                    },
                  },
                },
                {
                  name: 'date_to',
                  title: I18n.t('report.Date to'),
                  label: I18n.t('report.Date to'),
                  hyper_type: 'date',
                  inputProps: {
                    disabledDate: (endValue: any) => {
                      return this.disabledEndDate(this.dateFrom, endValue);
                    },
                  },
                },
              ]}
              showClear={false}
            />
          </Row>
          <ReportAccountDetailsData/>
        </Modal>
        <div style = {{overflow: 'auto', width: '100%'}} className = "small-table no-actions">
          <Table
            columns={columns}
            dataSource={data}
            bordered
            loading={loading}
            pagination={false}
            rowKey={(record: any) => record.account_id}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event: any) => {
                  this.props.getReportAccountDetails([...filter,
                    {attribute: 'account_id', value: record.account_id},
                  ]);
                  this.setState({visible: true, account_id: record.account_id});
                },
              };
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  data: state.report.report,
  filter: state.report.filter,
  loading: state.report.loading,
  accounts: state.started_data.accounts,
  is_mobile: state.auth.is_mobile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getReportAccountDetails,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ReportAccountData);

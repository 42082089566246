import * as React from 'react';
import { Layout } from 'antd';
import { Route, Switch, Redirect } from 'react-router';
import Error from '../../components/Error/Error';
import Login from '../../Login/Login';

const {
  Header, Footer, Sider, Content,
} = Layout;

class Light extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    //
  }

  public render() {
    return (
      <div>
        <Error/>
        <Layout style={{minHeight: '100vh'}}>
          <Content>
            <Switch>
              <Route exact path="/login" component={Login}/>
              <Route render={() => {
                return <Redirect to="/login"/>;
              }}/>
            </Switch>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default Light;

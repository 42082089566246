import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Spin } from 'antd';
import { AppState } from '../../reducers';
import BillList from './BillList';
import './style.scss';

class Bill extends React.Component<any, any> {

  render() {
    const {loading, loading_counterparty, loading_storage} = this.props;
    return (
      <Spin spinning={loading || loading_storage || loading_counterparty}>
        <Switch>
          <Route exact path="/bill/list" component={BillList}/>
        </Switch>
      </Spin>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  loading: state.purchase_order.loading,
  loading_storage: state.storage.loading,
  loading_counterparty: state.counterparty.loading,
});

export default connect(mapStateToProps)(Bill);

import * as React from 'react';
import { InputNumber, Input, Form, Select, Tooltip } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

class EditableCell extends React.Component<any, any> {
  isSubmit: boolean = false;
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    //
  }

  renderInput = (inputType: any, title: any, dataIndex: any, formik: any) => {
    const {options, inputProps, renderInput} = this.props;
    const {errors, touched, isSubmitting, values} = formik;
    if (isSubmitting) {
      this.isSubmit = true;
    }

    if (renderInput) {
      return renderInput({...formik, isSubmit: this.isSubmit, name: dataIndex, title, inputType});
    }

    if (inputType === 'select') {
      return <Form.Item
        { ...errors[dataIndex]
          && (touched[dataIndex] || this.isSubmit)
          && { validateStatus: 'error'}}
      >
        <Tooltip
          title={errors[dataIndex] === 'Required' ? '' : errors[dataIndex]}
        >
          <Select
            {...inputProps}
            showSearch
            style={{ width: '100%' }}
            placeholder={title}
            optionFilterProp="children"
            allowClear={true}
            value={values[dataIndex]}
            onBlur={(value) => {formik.handleBlur({target: {name: dataIndex, value}}); }}
            onChange={(value: any) => {
              formik.handleChange({
                target: {
                  name: dataIndex,
                  value,
                },
              });
            }}
            filterOption={(input: any, option: any) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {options.map((option: any) => <Select.Option value={option.value}>{option.title}</Select.Option>)}
          </Select>
        </Tooltip>
      </Form.Item>;
    }

    return <Form.Item
        { ...errors[dataIndex]
          && (touched[dataIndex] ||  this.isSubmit)
          && { validateStatus: 'error'}}
      >
        <Tooltip
          title={errors[dataIndex] === 'Required' ? '' : errors[dataIndex]}
        >
          <Input
            {...inputProps}
            placeholder={title}
            value={values[dataIndex]}
            type={inputType}
            name={dataIndex}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Tooltip>
    </Form.Item>;
  }

  public render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      EditableContext,
      ...restProps} = this.props;

    return (
      <EditableContext.Consumer>
        {(formik: any) => {
        return <td {...restProps} className ={ editing ? 'editable-item' : ''}>
          {editing ? this.renderInput(inputType, title, dataIndex, formik) : restProps.children}
        </td>;
      }}
      </EditableContext.Consumer>
    );
  }
}

export default EditableCell;

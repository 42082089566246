import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  clearData,
} from './PurchaseOrderActions';
import { AppState } from '../../reducers';
import PurchaseOrderCreateContent from './components/PurchaseOrderCreateContent/PurchaseOrderCreateContent';

class PurchaseOrderCreate extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentWillMount() {
    this.props.clearData();
  }

  public render() {
    return <PurchaseOrderCreateContent/>;
  }
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      clearData,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderCreate);

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../reducers';
import { Table, Modal } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router';
import ReportCounterpartyDetailsData from './ReportCounterpartyDetailsData';
import { getReportCounterpartyDetails } from '../ReportActions';

class ReportCounterpartyData extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    //
  }

  public render() {
    const {data, filter, loading, counterparties, is_mobile} = this.props;

    // tslint:disable-next-line: variable-name
    let date_from;
    // tslint:disable-next-line: variable-name
    let date_to;

    filter.forEach((item: any) => {
      if (item.attribute === 'date_from') {
        date_from = item.value;
      }
      if (item.attribute === 'date_to') {
        date_to = item.value;
      }
    });

    const renderNumber = (value: number) => (value || 0).formatNumber()

    const columns = [
      {
        title: I18n.t('report.Counterparty'),
        dataIndex: 'counterparty_id',
        key: 'counterparty_id',
        render: (counterpartyId: number) => {
          const index = _.findIndex(counterparties, ['id', counterpartyId]);
          return index > -1 ? counterparties[index].name : counterpartyId;
        },
      },
      {
        title: I18n.t('report.Remainder to', {date: moment(date_from).format('L')}),
        dataIndex: 'remainder',
        key: 'remainder',
        align: 'right' as any,
        render: renderNumber,
      },
      {
        title: I18n.t('report.Turnover'),
        children: [
          {
            title: I18n.t('report.Incoming'),
            dataIndex: 'incoming',
            key: 'incoming',
            align: 'right' as any,
            render: renderNumber,
          },
          {
            title: I18n.t('report.Outcoming'),
            dataIndex: 'outcoming',
            key: 'outcoming',
            align: 'right' as any,
            render: renderNumber,
          },
        ],
      },
      {
        title: I18n.t('report.Remainder to', {date: moment(date_to).format('L')}),
        align: 'right' as any,
        render: (item: any) => (
          ((typeof item.remainder === 'number' ? item.remainder : 0) + item.incoming - item.outcoming).formatNumber()
        ),
      },
    ];

    return (
      <React.Fragment>
        <Modal
          title={I18n.t('report.Intercompany by counterparty')}
          visible={this.state.visible}
          onCancel={() => {this.setState({visible: false}); }}
          footer={false}
          width={is_mobile ? '100%' : '90%'}
          // onCancel={this.handleCancel}
        >
          <ReportCounterpartyDetailsData/>
        </Modal>
        <div style = {{overflow: 'auto', width: '100%'}} className = "small-table">
          <Table
            columns={columns}
            dataSource={data}
            bordered
            loading={loading}
            pagination={false}
            rowKey={(record: any) => record.counterparty_id}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event: any) => {
                  // let url = '/report/counterparty_details?counterparty_id=' + record.counterparty_id;
                  // filter.forEach((item: any) => {
                  //   url += '&' + item.attribute + '=' + item.value;
                  // });
                  // this.props.history.push(url);
                  this.props.getReportCounterpartyDetails([...filter,
                    {attribute: 'counterparty_id', value: record.counterparty_id},
                  ]);
                  this.setState({visible: true});
                },
              };
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  data: state.report.report,
  filter: state.report.filter,
  loading: state.report.loading,
  counterparties: state.started_data.counterparties,
  is_mobile: state.auth.is_mobile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getReportCounterpartyDetails,
    },
    dispatch,
);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportCounterpartyData) as any);

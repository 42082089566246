import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../reducers';
import moment from 'moment';
import { getPayments, createPayment, updatePayment, deletePayment } from '../../PaymentActions';
import findIndex from 'lodash.findindex';
import cloneDeep from 'lodash.clonedeep';
import OrderItemsEditable from '../../../components/OrderItemsEditable/OrderItemsEditable';
import DateField from '../../../components/Fields/DateField';
import SelectField from '../../../components/Fields/SelectField';
import SwitchField from '../../../components/Fields/SwitchField';
import { I18n } from 'react-redux-i18n';
import TableColSettings from '../../../components/TableColSettings';

class PaymentTable extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    if (!localStorage.getItem('payment-table-col-setting')) {
      localStorage.setItem('payment-table-col-setting',
        'counterparty_id,account_id,p_type,currency_name,currency_rate,base_total,total,date,processed',
      );
    }
  }

  renderInput = (props: any) => {
    switch (props.inputType) {
      case 'date':
        return <div style = {{width: '115px'}}><DateField {...props}/></div>;
      case 'switch':
        return <SwitchField {...props} inputProps={{disabled: true}}/>;
      case 'select':
        let options: any = [];
        if (props.name === 'counterparty_id') {
          options = this.props.counterparties.map((item: any) => ({title: item.name, value: item.id}));
        }
        if (props.name === 'account_id') {
          options = this.props.accounts.map((item: any) => ({title: item.name, value: item.id}));
        }
        if (props.name === 'p_type') {
          options = this.props.dictionaries
            .map((item: any) => ({title: item.short_title, value: item.id.toString()}));
        }
        if (props.name === 'currency_name') {
          options = this.props.currency
            .map((item: any) => ({title: item.short_title, value: item.id.toString()}));
        }
        return <SelectField
          {...props}
          options={options}
          inputProps={{
            style: {
              minWidth: '100px',
              width: '100%',
            },
          }}
        />;
      default:
        return null;
    }
  }

  onTableCell = (col: any, record: any) => {

    switch (col.dataIndex) {
      case 'date':
        return {
          inputType: 'date',
          renderInput: this.renderInput,
        };
      case 'counterparty_id':
        return {
          inputType: 'select',
          renderInput: this.renderInput,
        };
      case 'account_id':
        return {
          inputType: 'select',
          renderInput: this.renderInput,
        };
      case 'p_type':
        return {
          inputType: 'select',
          renderInput: this.renderInput,
        };
      case 'currency_name':
        return {
          inputType: 'select',
          renderInput: this.renderInput,
        };
      case 'currency_rate':
        return {
          inputType: 'input',
          // style: {minWidth: '90px'},
        };
      case 'base_total':
          return {
            inputType: 'input',
            // style: {minWidth: '100px'},
          };
      case 'processed':
        return {
          inputType: 'switch',
          renderInput: this.renderInput,
        };
      default:
        return {
          inputType: 'text',
          // render: () => {
          //   return <div></div>;
          // },
        };
    }
  }

  createItem = (data: any) => {
    this.props.createPayment(data, this.props.filter);
  }
  updateItem = (data: any) => {
    this.props.updatePayment(data.id, data);
  }
  deleteItem = (id: any) => {
    this.props.deletePayment(id);
  }

  onChange = (pagination: any, filters: any, sorter: any) => {

    const filter: any = cloneDeep(this.props.filter);

    const index = findIndex(this.props.filter, ['attribute', 'sort']);
    const indexLimit = findIndex(this.props.filter, ['attribute', 'limit']);
    const indexSkip = findIndex(this.props.filter, ['attribute', 'skip']);
    if (indexLimit > -1) {
      const skip = parseFloat(pagination.current) * parseFloat(filter[indexLimit].value) - filter[indexLimit].value;
      (indexSkip > -1) ? filter[indexSkip].value = skip : filter.push({attribute: 'skip', value: skip});
    }

    if (!Object.keys(sorter).length) {
      this.props.getPayments(filter.filter((item: any) => item.attribute !== 'sort'));
      return;
    }

    if (index > -1) {
      filter[index].value = sorter.order === 'ascend' ? sorter.field : '-' + sorter.field;
    } else {
      filter.push({attribute: 'sort', value: sorter.order === 'ascend' ? sorter.field : '-' + sorter.field});
    }
    this.props.getPayments(filter);
  }

  public render() {
    const {data, counterparties, accounts, dictionaries,
      total_count, filter, currency, default_types, totals} = this.props;

    const tableData: any [] = data.map((item: any) => {
      if (item.processed) {
        let index = -1;
        delete item.row_color;
        if ( findIndex(dictionaries, ['id', parseInt(item.p_type, 10)]) > -1) {
          index = findIndex(dictionaries, ['id', parseInt(item.p_type, 10)]);
          if (dictionaries[index] && dictionaries[index].style && dictionaries[index].style.color) {
            item.row_color = dictionaries[index].style.color;
          }
        }
        if (!item.row_color && findIndex(currency, ['id', parseInt(item.currency_name, 10)]) > -1) {
          index = findIndex(currency, ['id', parseInt(item.currency_name, 10)]);
          if (currency[index] && currency[index].style && currency[index].style.color) {
            item.row_color = currency[index].style.color;
          }
        }
      } else {
        item.row_color = undefined;;
      }
      return item;
    });

    let sortedInfo: any = {};
    let limit = 0;

    filter.forEach((item: any) => {
      if (item.attribute === 'sort') {
        sortedInfo = {
          order: item.value[0] === '-' ? 'descend' : 'ascend',
          columnKey: item.value[0] === '-' ? item.value.slice(1) : item.value,
        };
      }
      if (item.attribute === 'limit') {
        limit = item.value;
      }
    });

    let propsTable = {};

    if ( total_count <= limit ) {
      propsTable = {
        bordered: true,
        pagination : false,
      };
    } else {
      propsTable = {
        bordered: true,
        onChange : (pagin: any, filters: any, sorter: any) => {this.onChange(pagin, filters, sorter); },
        pagination: {
          total : total_count + total_count / limit,
          pageSize: limit + 1,
        },
      };
    }

    let pagination = {};
    if ( total_count <= limit ) {
      pagination = false;
    } else {
      pagination = {
        total : total_count + total_count / limit,
        pageSize: limit + 1,
      };
    }

    const validateRules = [
      {name: 'counterparty_id', rules: ['required']},
      {name: 'base_total', rules: ['required', 'numeric']},
      {name: 'account_id', rules: ['required']},
      {name: 'p_type', rules: ['required']},
      {name: 'date', rules: ['required']},
      {name: 'currency_name', rules: ['required']},
      {name: 'currency_rate', rules: ['required', 'numeric']},
    ];

    const cols: any = (localStorage.getItem('payment-table-col-setting') || '').split(',');
    const hiddenCols: any = [ {title: 'ID', dataIndex: 'id'}];

    const columns: any[] = [
      {
        title: I18n.t('payment.Counterparty'),
        dataIndex: 'counterparty_id',
        editable: true,
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'counterparty_id' && sortedInfo.order,
        render: (value: any, record: any) => {
          const index = findIndex(counterparties, ['id', value]);
          return {
            props: { style: { background: record.row_color} },
            children: <div>{(index > -1) && value && counterparties[index].name}</div>,
          };
        },
      },
    ];

    if (cols.indexOf('account_id') > -1 || !default_types.account) {
      columns.push({
        title: I18n.t('payment.Account'),
        dataIndex: 'account_id',
        editable: true,
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'account_id' && sortedInfo.order,
        render: (value: any, record: any) => {
          const index = findIndex(accounts, ['id', value]);
          return {
            props: { style: { background: record.row_color} },
            children: <div>{(index > -1) && value && accounts[index].name}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({title: I18n.t('payment.Account'), dataIndex: 'account_id',
        render: (value: any) => {
          const index = findIndex(accounts, ['id', value]);
          return (index > -1) && value && accounts[index].name;
        }},
      );
    }

    if (cols.indexOf('p_type') > -1 || !default_types.bill) {
      columns.push({
        title: I18n.t('payment.Type'),
        dataIndex: 'p_type',
        editable: true,
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'p_type' && sortedInfo.order,
        render: (value: any, record: any) => {
          const index = findIndex(dictionaries, ['id', parseInt(value, 10)]);
          return {
            props: { style: { background: record.row_color} },
            children: <div>{(index > -1) && value && dictionaries[index].short_title}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({title: I18n.t('payment.Type'), dataIndex: 'p_type',
        render: (value: any) => {
          const index = findIndex(dictionaries, ['id', parseInt(value, 10)]);
          return (index > -1) && value && dictionaries[index].short_title;
        }},
      );
    }

    if (cols.indexOf('currency_name') > -1 || !default_types.currency) {
      columns.push({
        title: I18n.t('payment.Currency name'),
        dataIndex: 'currency_name',
        editable: true,
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'currency_name' && sortedInfo.order,
        render: (value: any, record: any) => {
          const index = findIndex(currency, ['id', parseInt(value, 10)]);
          return {
            props: { style: { background: record.row_color} },
            children: <div>{(index > -1) && value && currency[index].short_title}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({title: I18n.t('payment.Currency name'), dataIndex: 'currency_name',
        render: (value: any) => {
          const index = findIndex(currency, ['id', parseInt(value, 10)]);
          return (index > -1) && value && currency[index].short_title;
        }},
      );
    }

    if (cols.indexOf('currency_rate') > -1) {
      columns.push({
        title: I18n.t('payment.Currency rate'),
        dataIndex: 'currency_rate',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'currency_rate' && sortedInfo.order,
        editable: true,
        render: (value: any, record: any) => ({
          props: { style: { background: record.row_color} },
          children: <div>{value}</div>,
        }),
      });
    } else {
      hiddenCols.push({title: I18n.t('payment.Currency rate'), dataIndex: 'currency_rate' });
    }

    columns.push({
      title: I18n.t('payment.Base total'),
      dataIndex: 'base_total',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'base_total' && sortedInfo.order,
      editable: true,
      render: (value: any, record: any) => ({
        props: { style: { background: record.row_color} },
        children: <div>{value}</div>,
      }),
    });

    if (cols.indexOf('total') > -1) {
      columns.push({
        title: I18n.t('payment.Total'),
        dataIndex: 'total',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'total' && sortedInfo.order,
        editable: false,
        render: (value: any, record: any) => ({
          props: { style: { background: record.row_color} },
          children: <div>{value}</div>,
        }),
      });
    } else {
      hiddenCols.push({title: I18n.t('payment.Total'), dataIndex: 'total', render: (value: any) => (value || '0') });
    }

    if (cols.indexOf('date') > -1) {
      columns.push({
        title: I18n.t('payment.Date'),
        dataIndex: 'date',
        editable: true,
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
        render: (value: any, record: any) => ({
          props: { style: { background: record.row_color} },
          children: <div>{moment(value).format('YYYY-MM-DD')}</div>,
        }),
      });
    } else {
      hiddenCols.push({title: I18n.t('payment.Date'), dataIndex: 'date',
      render: (value: any) => moment(value).format('YYYY-MM-DD') });
    }

    return (
      <div style = {{overflow: 'auto', width: '100%'}} className = "small-table">
        <TableColSettings
          options = {[
            { label: I18n.t('payment.Counterparty'), value: 'counterparty_id', disabled: true },
            { label: I18n.t('payment.Account'), value: 'account_id', disabled: !default_types.account },
            { label: I18n.t('payment.Type'), value: 'p_type', disabled: !default_types.bill },
            { label: I18n.t('payment.Currency name'), value: 'currency_name', disabled: !default_types.currency },
            { label: I18n.t('payment.Currency rate'), value: 'currency_rate' },
            { label: I18n.t('payment.Base total'), value: 'base_total', disabled: true},
            { label: I18n.t('payment.Total'), value: 'total' },
            { label: I18n.t('payment.Date'), value: 'date' },
            { label: I18n.t('payment.Processed'), value: 'processed', disabled: true },
          ]}
          data = {localStorage.getItem('payment-table-col-setting')}
          onSave = {(values: any) => {
            this.setState({values});
            localStorage.setItem('payment-table-col-setting', values);
          }}
        />
        <OrderItemsEditable
          onChange={this.onChange}
          btnCreateDisabled={false}
          items={tableData}
          create={this.createItem}
          update={this.updateItem}
          delete={this.deleteItem}
          changeStatus = {(id: number, processed: boolean) => this.props.updatePayment(id, {processed})}

          validateRules={validateRules}
          columns={columns}
          onTableCell={this.onTableCell}
          propsTable={ propsTable }

          initionalNewRowValues = {{
            currency_rate: '1.00',
            p_type: default_types.payment && default_types.payment.toString(),
            account_id: default_types.account && default_types.account,
            currency_name: default_types.currency && default_types.currency.toString(),
            date: moment().format('YYYY-MM-DD'),
          }}

          btnCreateShow={false}
          showNewLine={true}
          enableReinitialize={true}
          actionUpdateShow={(item: any) => !item.processed}
          actionDeleteShow={(item: any) => !item.processed}
          pagination = {pagination}
          footer = {() => <div>
            {/* <p>Subtotal: {totals.total || '0.00'}</p> */}
            <p>{I18n.t('payment.Summary Total')}: {totals.total || '0.00'}</p>
          </div>}
          operationsOnLeft = {true}
          expandedRowRender = { (record: any) =>
            hiddenCols.map((item: any) => <div key={item.dataIndex}>
              {item.title} : {item.render ? item.render(record[item.dataIndex]) : record[item.dataIndex]}
            </div>)
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  data: state.payment.list_data,
  filter: state.payment.filter,
  total_count: state.payment.total_count,
  totals: state.payment.totals,
  counterparties: state.started_data.counterparties,
  dictionaries: state.started_data.dictionaries.filter((item: any) => item.d_type === 'payment'),
  currency: state.started_data.dictionaries.filter((item: any) => item.d_type === 'currency'),
  accounts: state.started_data.accounts,
  default_types: state.default_values.values,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getPayments,
      createPayment,
      updatePayment,
      deletePayment,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTable);

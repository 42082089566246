import { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Table } from 'antd';
import findIndex from 'lodash.findindex';
import cloneDeep from 'lodash.clonedeep';
import { I18n } from 'react-redux-i18n';
import { AppState } from '../../../../reducers';
import { getReportStorage } from '../../ReportActions';

function ReportAccountData({ data, filter, total_count, getReportStorage }: any) {
  const onChange = useCallback(
    (pagination: any) => {
      const _filter: any = cloneDeep(filter);
      const indexLimit = findIndex(filter, ['attribute', 'limit']);
      const indexSkip = findIndex(filter, ['attribute', 'skip']);
      if (indexLimit > -1) {
        const skip = parseFloat(pagination.current) * parseFloat(_filter[indexLimit].value) - _filter[indexLimit].value;
        indexSkip > -1 ? (_filter[indexSkip].value = skip) : _filter.push({ attribute: 'skip', value: skip });
      }

      getReportStorage(_filter);
    },
    [filter, getReportStorage]
  );

  const limit = useMemo(() => (filter.find((item: any) => item.attribute === 'limit') || { value: 0 }).value, [filter]);

  let pagination = useMemo(
    () =>
      total_count <= limit
        ? false
        : {
            total: total_count,
            pageSize: limit,
          },
    [limit, total_count]
  );

  const columns = useMemo(
    () => [
      {
        title: I18n.t('report.Product'),
        dataIndex: 'title',
        key: 'title',
        sorter: (a: any, b: any) => ('' + a.title).localeCompare(b.title),
      },
      {
        title: I18n.t('report.Count'),
        dataIndex: 'count',
        key: 'count',
        align: 'right' as any,
        width: '15%',
        sorter: (a: any, b: any) => a.count - b.count,
      },
      {
        title: I18n.t('report.AvgPrice'),
        dataIndex: 'price',
        key: 'price',
        align: 'right' as any,
        width: '15%',
        render: (_: any, item: any) => {
          return item.count ? (item.total / item.count).formatNumber() : '';
        },
        sorter: (a: any, b: any) => {
          const aAvgPrice = a.count ? a.total / a.count : 0;
          const bAvgPrice = b.count ? b.total / b.count : 0;
          return aAvgPrice - bAvgPrice;
        },
      },
      {
        title: I18n.t('report.Total'),
        dataIndex: 'total',
        key: 'total',
        align: 'right' as any,
        width: '15%',
        render: (total: any) => {
          return total ? total.formatNumber() : '';
        },
        sorter: (a: any, b: any) => a.total - b.total,
      },
    ],
    []
  );

  const total = useMemo(
    () => (Array.isArray(data) ? data.reduce((_sum, item) => _sum + (item.total || 0), 0) : 0),
    [data]
  );

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={data}
      onChange={onChange}
      bordered
      pagination={pagination}
      showSorterTooltip={false}
      sortDirections={['ascend', 'descend']}
      footer={() => (
        <div>
          {I18n.t('bill.Summary Total')}: {total.formatNumber()}
        </div>
      )}
    />
  );
}

const mapStateToProps = (state: AppState) => ({
  total_count: state.report.total_count,
  data: state.report.report,
  filter: state.report.filter,
  accounts: state.started_data.accounts,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getReportStorage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReportAccountData);

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';


Number.prototype.formatNumber = function (locale): string  {
  return this.valueOf().toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}
String.prototype.formatNumber = function (locale): string  {
  const num = parseFloat(this.valueOf())
  return isNaN(num) ? '' : num.formatNumber(locale)
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


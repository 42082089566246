import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getOrder, setReadingMode } from './TransferOrderActions';
import { AppState } from '../../reducers';
import TransferOrderCreateContent from './components/TransferOrderCreateContent/TransferOrderCreateContent';

class TransferOrderView extends React.Component<any, any> {

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      this.props.getOrder(this.props.match.params.id, []);
    }
    this.props.setReadingMode(true);
  }

  public render() {
    return (
      <TransferOrderCreateContent />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  items: state.transfer_order.items,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setReadingMode,
      getOrder,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(TransferOrderView);

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Route, Switch, Redirect } from 'react-router';
import { Spin } from 'antd';
import { AppState } from '../../reducers';
import PaymentList from './PaymentList';

class Payment extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    //
  }

  public render() {
    const {loading, loading_counterparty, loading_storage} = this.props;
    return (
      <Spin spinning={loading || loading_storage || loading_counterparty}>
        <Switch>
          <Route exact path="/payment/list" component={PaymentList}/>
        </Switch>
      </Spin>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  loading: state.purchase_order.loading,
  loading_storage: state.storage.loading,
  loading_counterparty: state.counterparty.loading,
});

export default connect(mapStateToProps)(Payment);

import * as React from 'react';
import { Table, Popconfirm, Form, InputNumber, Input, Button } from 'antd';
import EditableFormRow from './EditableFormRow';
import EditableCell from './EditableCell';
import './OrderItemsEditable.scss';
import { I18n } from 'react-redux-i18n';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  StopOutlined,
} from '@ant-design/icons';

const EditableContext = React.createContext({});

export interface PropsComponent {
  delete: any;
  create: any;
  update: any;
  btnCreateDisabled: boolean;
  products: any[];
  collums: any[];
  validateRules: any[];
  onTableCell: any;
  insertNewRow: string;
  initionalNewRowValues: any;
  btnCreateShow: boolean;
  propsTable: any;
  showNewLine: boolean;
  showOperations: boolean;
}

class OrderItemsEditable extends React.Component<any, any> {
  static defaultProps = {
    delete: () => {
      //
    },
    create: () => {
      //
    },
    update: () => {
      //
    },
    btnCreateDisabled: true,
    products: [],
    collums: [],
    validateRules: [],
    onTableCell: () => ({}),
    insertNewRow: 'last',
    initionalNewRowValues: {},
    btnCreateShow: true,
    propsTable: {},
    showNewLine: false,
    showOperations: true,
    actionDeleteShow: () => (true),
    actionUpdateShow: () => (true),
  };

  defaultItem: any = {
    id: 'new',
  };

  constructor(props: any) {
    super(props);
    this.state = {
      editingKey: '',
      editingData: {},
      newData: this.props.initionalNewRowValues,
      isInserting: this.props.showNewLine,
    };

  }

  componentDidMount() {
    //
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({
      isInserting: nextProps.showNewLine,
      newData: this.props.initionalNewRowValues,
    });
  }

  isEditing = (record: any) => record.id === this.state.editingKey || record.id === 'new';

  save = (values: any) => {
    if (!values.id || values.id === 'new') {
      this.props.create(values);
      this.setState({newData: values});
    } else {
      this.props.update(values);
    }
    this.setState({editingKey: '', isInserting: false || this.props.showNewLine});
  }

  cancel = (data: any) => {
    if (data.id === 'new') {
      this.setState({ isInserting: false || this.props.showNewLine, newData: {}});
    } else {
      this.setState({ editingKey: '', isInserting: false || this.props.showNewLine});
    }
  }

  edit(data: any) {
    if (data.id === 'new') {
      this.setState({ newData: data });
    } else {
      this.setState({ editingKey: data.id, editingData: data });
    }
  }

  add = () => {
    this.setState({
      isInserting: true,
      // editingKey: 'new',
      // editingData: this.props.initionalNewRowValues,
      newData: this.props.initionalNewRowValues,
    });
  }

  customExpandIcon(props: any) {
      if (props.expanded) {
          return <a onClick={(e: any) => {
              props.onExpand(props.record, e);
          }}><CaretUpOutlined /></a>;
      } else {
          return <a  onClick={(e: any) => {
              props.onExpand(props.record, e);
          }}><CaretDownOutlined /></a>;
      }
  }

  public render() {

    const {propsTable,
      btnCreateShow,
      columns,
      validateRules,
      onTableCell,
      btnCreateDisabled,
      actionUpdateShow,
      actionDeleteShow,
      showOperations,
      operationsOnLeft,
      onChange,
      pagination,
      footer,
      expandedRowRender,
    } = this.props;

    let columnsTmp: any = [
      ...columns,
    ];

    if (showOperations) {
      const operations = {
        title: I18n.t('order_item_editable.Operation'),
        dataIndex: 'operation',
        render: (text: any, record: any) => {
          const editable = this.isEditing(record);
          return ({
            props: { style: { background: record.row_color} },
            children: <div style = {{padding: '6px 0'}}>
              {editable ? (
                <span style = {{whiteSpace: 'nowrap'}}>
                  <EditableContext.Consumer>
                    {(formik: any) => (
                      <a onClick={formik.handleSubmit} >
                        <SaveOutlined />
                      </a>
                    )}
                  </EditableContext.Consumer>
                  <span> </span>
                    <a onClick={() => {this.cancel(record); }}><CloseOutlined /></a>
                </span>
                ) : (
                  <span style = {{whiteSpace: 'nowrap'}}>
                    {operationsOnLeft && this.props.changeStatus ?
                      record.processed ?
                      <a onClick = {() => this.props.changeStatus(record.id, false)} ><StopOutlined /></a> :
                      <a onClick = {() => this.props.changeStatus(record.id, true)} ><CheckOutlined /></a> :
                      ''
                    }
                    {actionUpdateShow(record) ? <a onClick={() => this.edit(record)}><EditOutlined /></a> : null}
                    <span> </span>
                    {actionDeleteShow(record) ? <Popconfirm
                      title= {I18n.t('order_item_editable.Sure to delete?')}
                      onConfirm={() => this.props.delete(record.id)}
                      cancelText = {I18n.t('order_item_editable.Cancel')}
                      okText = {I18n.t('order_item_editable.Ok')}
                    >
                      <a><DeleteOutlined /></a>
                    </Popconfirm> : null}
                  </span>
                )}
              </div>,
            });
          },
        };

      operationsOnLeft ? columnsTmp.unshift(operations) : columnsTmp.push(operations) ;
    }

    columnsTmp = columnsTmp.map((col: any) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: any) => ({
          ...onTableCell(col, record),
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    const components: any = {
      body: {
        row: (props: any) => <EditableFormRow
          {...props}
          newData={this.state.newData}
          editingData={this.state.editingData}
          save={this.save}
          EditableContext={EditableContext}
          validateRules={validateRules}
        />,
        cell: (props: any) => <EditableCell
          {...props}
          EditableContext={EditableContext}
        />,
      },
    };

    return (
      <div style={{ width: '100%' }}>
        {btnCreateShow ? <Button
          key="btn-create"
          type="primary"
          ghost
          style={{marginBottom: '5px'}}
          // shape="circle"
          icon="plus"
          disabled={btnCreateDisabled}
          className=""
          onClick={() => {
            this.add();
          }}
        >New line</Button> : null}
        <Table
          {...propsTable}
          onChange = {onChange}
          components={components}
          className={'order-items'}
          key="item-order"
          dataSource={this.state.isInserting
            ?
              // tslint:disable-next-line: triple-equals
              (this.props.insertNewRow == 'first' ?
                [this.defaultItem, ...this.props.items] : [...this.props.items, this.defaultItem])
            : this.props.items}
          columns={columnsTmp}
          rowClassName= {(record: any) => 'editable-row'}
          rowKey={(record: any) => record.id}
          pagination = {pagination ? pagination : false}
          footer = {footer}
          expandedRowRender = {expandedRowRender}
          expandIcon={(props: any) =>
            expandedRowRender && props.record.createdAt ? this.customExpandIcon(props) : <div />
          }
        />
      </div>
    );
  }
}

export default OrderItemsEditable;

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Modal } from 'antd';
import { AppState } from '../../../../reducers';
import { setShowModal, setReadingMode, clearOrder } from '../../SalesOrderActions';
import SalesOrderCreateContent from '../SalesOrderCreateContent/SalesOrderCreateContent';

class ModalComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    //
  }

  handleOk = () => {
    this.props.setShowModal(false);
    this.props.setReadingMode(false);
  }

  handleCancel = () => {
    this.props.setShowModal(false);
    this.props.setReadingMode(false);
    this.props.clearOrder();
  }

  public render() {
    const {show_modal, title, loading, is_mobile}: any = this.props;
    return (
      <Modal
        mask = {true}
        maskClosable = {false}
        title={title}
        width={is_mobile ? '100%' : '90%'}
        visible={show_modal && !loading}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer= {null}
        destroyOnClose = {true}
        forceRender
      >
        <SalesOrderCreateContent/>
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  show_modal: state.sales_order.show_modal,
  loading: state.sales_order.loading,
  is_mobile: state.auth.is_mobile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setShowModal,
      setReadingMode,
      clearOrder,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ModalComponent);

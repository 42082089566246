import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../reducers';
import { Table, Modal } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { I18n } from 'react-redux-i18n';

class ReportCounterpartyDetailsData extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    //
  }

  public render() {
    const {data, loading} = this.props;

    const columns = [
      {
        title: I18n.t('report.Date'),
        dataIndex: 'date',
        key: 'date',
        render: (date: any) => {
          return date ? moment(date).format('L') : '';
        },
      },
      {
        title: I18n.t('report.Type'),
        render: (row: any) => {
          switch (row.type) {
            case 'bill':
              return I18n.t('report.Bill');
            case 'payment':
              return I18n.t('report.Payment');
            case 'purchase_order':
              return I18n.t('report.Purchase order');
            case 'sales_order':
              return I18n.t('report.Sales order');
            default:
              return '';
          }
        },
      },
      {
        title: I18n.t('report.Incoming'),
        align: 'right' as any,
        render: (row: any) => {
          if (row.type === 'bill' || row.type === 'purchase_order') {
            return row.total.formatNumber();
          }
          if (row.hasOwnProperty('incoming')) {
            return row.incoming.formatNumber();
          }
          return '';
        },
      },
      {
        title: I18n.t('report.Outcoming'),
        align: 'right' as any,
        render: (row: any) => {
          if (row.type === 'payment' || row.type === 'sales_order') {
            return row.total.formatNumber();
          }
          if (row.hasOwnProperty('outcoming')) {
            return row.outcoming.formatNumber();
          }
          return '';
        },
      },
      {
        title: I18n.t('report.Note'),
        dataIndex: 'note',
        key: 'note',
      },
    ];

    return (
      <div style = {{overflow: 'auto', width: '100%'}}>
        <Table
          columns={columns}
          dataSource={data}
          bordered
          pagination={false}
          loading={loading}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  data: state.report.report_details,
  filter: state.report.filter,
  loading: state.report.loading,
  counterparties: state.started_data.counterparties,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ReportCounterpartyDetailsData);

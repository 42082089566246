export const GET_PURCHASE_ORDERS = 'GET_PURCHASE_ORDERS';
export const PURCHASE_ORDERS_RECEIVED = 'PURCHASE_ORDERS_RECEIVED';

export const SET_PURCHASE_ORDERS_FILTER = 'SET_PURCHASE_ORDERS_FILTER';

export const GET_PURCHASE_ORDER = 'GET_PURCHASE_ORDER';
export const PURCHASE_ORDER_RECEIVED = 'PURCHASE_ORDER_RECEIVED';

export const CREATE_PURCHASE_ORDER_ITEM = 'CREATE_PURCHASE_ORDER_ITEM';
export const UPDATE_PURCHASE_ORDER_ITEM = 'UPDATE_PURCHASE_ORDER_ITEM';
export const DELETE_PURCHASE_ORDER_ITEM = 'DELETE_PURCHASE_ORDER_ITEM';
export const UPDATE_PURCHASE_ORDER_ITEM_RECEIVED = 'UPDATE_PURCHASE_ORDER_ITEM_RECEIVED';
export const CREATE_PURCHASE_ORDER_ITEM_RECEIVED = 'CREATE_PURCHASE_ORDER_ITEM_RECEIVED';
export const DELETE_PURCHASE_ORDER_ITEM_RECEIVED = 'DELETE_PURCHASE_ORDER_ITEM_RECEIVED';
export const PURCHASE_ORDER_ITEMS_RECEIVED = 'PURCHASE_ORDER_ITEMS_RECEIVED';

export const CREATE_PURCHASE_ORDER = 'CREATE_PURCHASE_ORDER';
export const CREATE_PURCHASE_ORDER_RECEIVED = 'CREATE_PURCHASE_ORDER_RECEIVED';
export const UPDATE_PURCHASE_ORDER = 'UPDATE_PURCHASE_ORDER';
export const UPDATE_PURCHASE_ORDER_RECEIVED = 'UPDATE_PURCHASE_ORDER_RECEIVED';

// export const PURCHASE_ORDER_DATA_CREATE = 'PURCHASE_ORDER_DATA_CREATE';
// export const PURCHASE_ORDER_DATA_CREATE_RECEIVED = 'PURCHASE_ORDER_DATA_CREATE_RECEIVED';

export const PURCHASE_ORDER_CLEAR_DATA = 'PURCHASE_ORDER_CLEAR_DATA';

export const PURCHASE_ORDER_PROCESSED = 'PURCHASE_ORDER_PROCESSED';
export const PURCHASE_ORDER_PROCESSED_RECEIVED = 'PURCHASE_ORDER_PROCESSED_RECEIVED';
export const SET_SHOWMODAL_PURCHASE_ORDER = 'SET_SHOWMODAL_PURCHASE_ORDER';
export const PURCHASE_ORDER_UPDATE = 'PURCHASE_ORDER_UPDATE';
export const SET_PURCHASE_ORDER_READING_MODE = 'SET_PURCHASE_ORDER_READING_MODE';
export const PURCHASE_ORDER_SET_TOTALS = 'PURCHASE_ORDER_SET_TOTALS';
export const DELETE_PURCHASE_ORDER = 'DELETE_PURCHASE_ORDER';

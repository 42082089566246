import * as React from 'react';
import { Input, Button, Select, DatePicker, Row, Col, Form } from 'antd';
import moment from 'moment';
import histoty from '../../../../lib/histoty';
import { I18n } from 'react-redux-i18n';
import isEqual from 'lodash.isequal';
import locale from 'antd/es/date-picker/locale/uk_UA';

class TransferOrderFormContent extends React.Component<any, any> {
  isSubmit: boolean;
  constructor(props: any) {
    super(props);
    this.isSubmit = false;
  }

  componentDidMount() {
    //
  }

  public render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      storages,
      isSubmitting,
      loading,
      isReadingMode,
      currency,
      types,
      initialValues,
    }: any = this.props;

    if (isSubmitting) {
      this.isSubmit = true;
    }

    let disableButton: boolean = false;
    if ( initialValues.id && isEqual(initialValues, values)) {
      disableButton = true;
    }

    return (
      <Form onFinish={handleSubmit} className="transfer-order-form" layout="vertical">
        <h3 >{isReadingMode ? I18n.t('transfer_order.IS PROCESSED') : ''} </h3>
        <div className='transferCreate-modal'>
        <Row gutter={16}>
          <Col span={12} xs = {24}>
            <Form.Item
              { ...errors.date && (touched.date ||  this.isSubmit) && { help : errors.date, validateStatus: 'error'}}
              label={I18n.t('transfer_order.Date')}
            >
              <DatePicker
                locale={locale}
                disabled = {isReadingMode ? true : false}
                defaultValue={values.date && moment(values.date)}
                value={values.date && moment(values.date)}
                placeholder={I18n.t('transfer_order.Date')}
                onOpenChange={(status: any) => {
                  if (!status) {
                    handleBlur({target: {name: 'date'}});
                  }
                }}
                style={{width: '100%'}}
                onChange={(date) => {
                  this.props.handleChange({
                    target: {
                      name: 'date',
                      value: date,
                    },
                  });
                }}
              />
            </Form.Item>
          </Col>

          <Col span={12} xs = {24}>
          <Form.Item
              { ...errors.storage_from_id
                && (touched.storage_from_id ||  this.isSubmit)
                && { help : errors.storage_from_id, validateStatus: 'error'}
              }
                label={I18n.t('transfer_order.Storage')}
            >
              <Select
                disabled = {isReadingMode ? true : false}
                showSearch
                style={{ width: '100%' }}
                placeholder={I18n.t('transfer_order.Storage From')}
                optionFilterProp="children"
                allowClear={true}
                value={values.storage_from_id}
                onBlur={(value) => {handleBlur({target: {name: 'storage_from_id', value}}); }}
                onChange={(value: any) => {
                  handleChange({
                    target: {
                      name: 'storage_from_id',
                      value,
                    },
                  });
                }}
                filterOption={(input: any, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {storages.map((option: any) =>
                  <Select.Option key = {option.value} value={option.value}>{option.title}</Select.Option>)
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8} xs = {12}>
            <Form.Item
              { ...errors.storage_to_id
                && (touched.storage_to_id ||  this.isSubmit)
                && { help : errors.storage_to_id, validateStatus: 'error'}
              }
                label={I18n.t('transfer_order.Storage')}
            >
              <Select
                disabled = {isReadingMode ? true : false}
                showSearch
                style={{ width: '100%' }}
                placeholder={I18n.t('transfer_order.Storage To')}
                optionFilterProp="children"
                allowClear={true}
                value={values.storage_to_id}
                onBlur={(value) => {handleBlur({target: {name: 'storage_to_id', value}}); }}
                onChange={(value: any) => {
                  handleChange({
                    target: {
                      name: 'storage_to_id',
                      value,
                    },
                  });
                }}
                filterOption={(input: any, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {storages.map((option: any) =>
                  <Select.Option key = {option.value} value={option.value}>{option.title}</Select.Option>)
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} xs = {12}>
            <Form.Item
              { ...errors.doc_type
                && (touched.doc_type ||  this.isSubmit)
                && { help : errors.doc_type, validateStatus: 'error'}
              }
              label={I18n.t('transfer_order.Type')}
            >
              <Select
                disabled = {isReadingMode ? true : false}
                showSearch
                style={{width: '100%'}}
                placeholder={I18n.t('transfer_order.Type')}
                optionFilterProp="children"
                allowClear={true}
                value = { values.doc_type}
                onBlur={(value) => {handleBlur({target: {name: 'doc_type', value}}); }}
                onChange={(value: string) => {
                  handleChange({
                    target: {
                      name: 'doc_type',
                      value,
                    },
                  });
                }}
                filterOption={(input: any, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {types.map((option: any) =>
                  <Select.Option key={option.value} value={option.value.toString()}>{option.title}</Select.Option>)
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} xs = {24}>
            <Form.Item
              // validateStatus={'error'}
              // help={'Error'}
              label={I18n.t('transfer_order.Note')}
            >
              <Input
                disabled = {isReadingMode ? true : false}
                placeholder={I18n.t('transfer_order.Note')}
                name={'note'}
                value={values.note}
                onChange={(e) => {
                handleChange({
                  target: {
                    name: 'note',
                    value: e.target.value,
                  },
                });
              }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              { ...errors.currency_name
                && (touched.currency_name ||  this.isSubmit)
                && { help : errors.currency_name, validateStatus: 'error'}
              }
                label={I18n.t('transfer_order.Currency name')}
            >
              <Select
                disabled = {isReadingMode ? true : false}
                showSearch
                style={{ width: '100%' }}
                placeholder={I18n.t('transfer_order.Currency name')}
                optionFilterProp="children"
                allowClear={true}
                value={values.currency_name }
                onBlur={(value ) => { handleBlur({target: {name: 'currency_name', value}}); }}
                onChange={(value ) => {
                  handleChange({
                    target: {
                      name: 'currency_name',
                      value,
                    },
                  });
                }}
                filterOption={(input: any, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {currency.map((option: any) =>
                  <Select.Option key = {option} value={option.value.toString()}>{option.title}</Select.Option>)
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              { ...errors.currency_rate
                && (touched.currency_rate ||  this.isSubmit)
                && { help : errors.currency_rate, validateStatus: 'error'}
              }
              label={I18n.t('transfer_order.Currency rate')}
            >
              <Input
                disabled = {isReadingMode ? true : false}
                placeholder={I18n.t('transfer_order.Currency rate')}
                name={'currency_rate'}
                value={values.currency_rate}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: 'currency_rate',
                      value: e.target.value,
                    },
                  });
              }} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              { ...errors.discount
                && (touched.discount ||  this.isSubmit)
                && { help : errors.discount, validateStatus: 'error'}
              }
              label={I18n.t('transfer_order.Discount')}
            >
              <Input
                disabled = {isReadingMode ? true : false}
                placeholder={I18n.t('transfer_order.Discount')}
                name={'discount'}
                value={values.discount}
                onBlur={(value) => {handleBlur({target: {name: 'discount', value}}); }}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: 'discount',
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        { !isReadingMode ?
          <Form.Item >
            <Button
              disabled = {disableButton}
              htmlType="submit"
              type="primary"
              loading={loading}>
              {I18n.t('transfer_order.Save')}
            </Button>
            <Button htmlType="button" style={{marginLeft: '10px'}} type="default"
              onClick={() => {
                if (this.props.setShowModal) {
                  this.props.setShowModal(false);
                }
                histoty.push('/transfer_order/list');
              }}>
              {I18n.t('transfer_order.Cancel')}
            </Button>
          </Form.Item>  : ''}
        </div>  
      </Form>
    );
  }
}

export default TransferOrderFormContent;

import * as React from 'react';
import {Form, DatePicker} from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/uk_UA';

class DateField extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    //
  }

  public render() {
    const {errors, touched, handleBlur, handleChange, isSubmit, name, values} = this.props;
    return (
      <Form.Item
        { ...errors[name]
          && (touched[name] ||  isSubmit)
          && { help : errors[name], validateStatus: 'error'}}
      >
        <DatePicker
          locale={locale}
          style={{width: '100%'}}
          onChange={(value) => {
            handleChange({target: {
              value,
              name,
            }});
          }}
          value={values[name] && moment(values[name]).isValid() && moment(values[name])}
          // value={moment(values[name]).isValid() ? moment(values[name]) : undefined}
        />
      </Form.Item>
    );
  }
}

export default DateField;

import * as React from 'react';
import {Form, Select } from 'antd';

class FieldSelect extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: [],
      value: undefined,
    };
  }

  componentDidMount() {
    //
  }

  handleChange = (value: any) => {
    const {attribute} = this.props;
    const valueAtt: any = {
      attribute: attribute.name,
      value,
    };

    if (attribute.comparison) {
      valueAtt.comparison = attribute.comparison;
    }
    this.props.handleChange({
      target: {
        name: attribute.name,
        value: value ? valueAtt : '',
      },
    });
    setTimeout(() => {
      this.props.handleSubmit();
    }, 200);
  }

  public render() {
    const {attribute, values, is_mobile} = this.props;

    let value;
    if (values) {
      value = values.value;
    }

    return (
      <Form.Item>
        <Select
          showSearch
          style={{ width: is_mobile ? '100%' : 168 }}
          placeholder={attribute.title}
          optionFilterProp="children"
          allowClear={true}
          value={value}
          onChange={this.handleChange}
          filterOption={(input: any, option: any) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {attribute.options.map((option: any) => <Select.Option
            key = { option.title + '-' + option.value} value={option.value}>{option.title}</Select.Option>)}
        </Select>
      </Form.Item>
    );
  }
}

export default FieldSelect;

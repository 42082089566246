import { DELETE_STARTED_DATA_ITEM,
  UPDATE_STARTED_DATA_ITEM,
  CREATE_STARTED_DATA_ITEM, 
  UPDATE_STARTED_DATA_PRODUCT} from './../types/StartedDataTypes';
import { put, takeLatest } from 'redux-saga/effects';
import restClient from '../config/rest-client';
import { ERROR } from '../types/ErrorTypes';
import generateFilter from '../lib/genFilter';
import {
  GET_PRODUCTS,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  PRODUCTS_RECEIVED,
  SET_PRODUCTS_FILTER,
  DELETE_PRODUCT_RECEIVED,
  CREATE_PRODUCT_RECEIVED,
  UPDATE_PRODUCT_RECEIVED,
  } from './../types/ProductTypes';
import { GenericEntitiesResponse, GenericEntityResponse } from '../types/baseTypes';
import { setValueByKey } from '../lib/storage';
import { STORAGE_PRODUCTS_FILTERS } from '../types/Constants';

function* getProducts({filter}: any) {
  setValueByKey(STORAGE_PRODUCTS_FILTERS, filter)
  try {
    const {data, count}: GenericEntitiesResponse = yield restClient.get('products' , '', generateFilter(filter));
    yield put({ type: PRODUCTS_RECEIVED, data, count, filter});
    yield put({ type: SET_PRODUCTS_FILTER, filter });
  } catch (error) {
    yield put({ type: PRODUCTS_RECEIVED, data: [], count: 0 });
    yield put({ type: ERROR, messages: ['Not products']});
  }
}

function* createProduct({data}: any) {
  try {
    const product: GenericEntityResponse = yield restClient.post('products' , data );
    yield put({ type: CREATE_PRODUCT_RECEIVED, product });
    const {id, title, price, published } = product;
    yield put({ type: CREATE_STARTED_DATA_ITEM, name: 'products',
      item: {id, title, price, published: published ? true : false },
    });
  } catch (error) {
    yield put({ type: CREATE_PRODUCT_RECEIVED, product: {} });
    yield put({ type: ERROR, messages: ['Not product create']});
  }
}

function* updateProduct({data, id}: any) {
  try {
    const product: GenericEntityResponse = yield restClient.update('products' , id, data);
    yield put({ type: UPDATE_PRODUCT_RECEIVED, product, id });
    yield put({ type: UPDATE_STARTED_DATA_PRODUCT, item: product, id });
  } catch (error) {
    yield put({ type: UPDATE_PRODUCT_RECEIVED, product: {} });
    yield put({ type: ERROR, messages: ['Not product update']});
  }
}

function* deleteProduct({id}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.delete('products', id);
    yield put({ type: DELETE_PRODUCT_RECEIVED, id });
    yield put({ type: DELETE_STARTED_DATA_ITEM, name: 'products', id });
  } catch (error) {
    yield put({ type: ERROR, messages: ['Not product delete ']});
  }
}

export default function* actionWatcherProduct() {
  yield takeLatest(GET_PRODUCTS, getProducts);
  yield takeLatest(CREATE_PRODUCT, createProduct);
  yield takeLatest(UPDATE_PRODUCT, updateProduct);
  yield takeLatest(DELETE_PRODUCT, deleteProduct);
}

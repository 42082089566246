import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Row } from 'antd';
import { AppState } from '../../reducers';
import OrderItemsEditable from '../components/OrderItemsEditable/OrderItemsEditable';
import {
  createAccount,
  updateAccount,
  deleteAccount,
  getAccounts,
} from './AccountActions';
import { I18n } from 'react-redux-i18n';
import cloneDeep from 'lodash.clonedeep';
import findIndex from 'lodash.findindex';

class AccountList extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    const retrievedObject: any = localStorage.getItem('default_values') || '{}';
    const account_limit: number = JSON.parse(retrievedObject).account_limit;
    const filter: any = cloneDeep(this.props.filter);

    const indexLimit = findIndex(this.props.filter, ['attribute', 'limit']);
    if (indexLimit > -1) {
      filter[indexLimit].value = account_limit || 1000;
    } else {
      filter.push({ attribute: 'limit', value: 1000 });
    }

    const indexSkip = findIndex(this.props.filter, ['attribute', 'skip']);
    if (indexSkip > -1) {
      filter[indexSkip].value = 0;
    }

    this.props.getAccounts(filter);
  }

  updateItem = (data: any) => {
    this.props.updateAccount(data.id, data);
  }

  deleteItem = (data: any) => {
    this.props.deleteAccount(data);
  }

  createItem = (data: any) => {
    this.props.createAccount({...data});
  }

  onChange = (pagination: any, filters: any, sorter: any) => {

    const filter: any = cloneDeep(this.props.filter);

    const index = findIndex(this.props.filter, ['attribute', 'sort']);
    const indexLimit = findIndex(this.props.filter, ['attribute', 'limit']);
    const indexSkip = findIndex(this.props.filter, ['attribute', 'skip']);
    if (indexLimit > -1) {
      const skip = parseFloat(pagination.current) * parseFloat(filter[indexLimit].value) - filter[indexLimit].value;
      (indexSkip > -1) ? filter[indexSkip].value = skip : filter.push({attribute: 'skip', value: skip});
    }

    if (!Object.keys(sorter).length) {
      this.props.getAccounts(filter.filter((item: any) => item.attribute !== 'sort'));
      return;
    }

    if (index > -1) {
      filter[index].value = sorter.order === 'ascend' ? sorter.field : '-' + sorter.field;
    } else {
      filter.push({attribute: 'sort', value: sorter.order === 'ascend' ? sorter.field : '-' + sorter.field});
    }
    this.props.getAccounts(filter);
  }

  public render() {
    const {accounts, filter, total_count} = this.props;

    let pagination = {};
    let limit = 0;

    let propsTable = {};
    if ( total_count <= limit ) {
      propsTable = {
        bordered: true,
        pagination : false,
      };
    } else {
      propsTable = {
        bordered: true,
        onChange : (pagin: any, filters: any, sorter: any) => {this.onChange(pagin, filters, sorter); },
        pagination: {
          total : total_count + total_count / limit,
          pageSize: limit + 1,
        },
      };
    }

    filter.forEach((item: any) => {
      if (item.attribute === 'limit') {
        limit = item.value;
      }
    });

    if ( total_count <= limit ) {
      pagination = false;
    } else {
      pagination = {
        total : total_count + total_count / limit,
        pageSize: limit + 1,
      };
    }

    const validateRules = [
      {name: 'name', rules: ['required']},
    ];

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: I18n.t('account.Name'),
        dataIndex: 'name',
        editable: true,
      },
    ];

    return (
      <div style = {{overflow: 'auto'}} className = "small-table simple-actions no-status">
        <Row>
          <OrderItemsEditable
            onChange={this.onChange}
            insertNewRow = "first"
            btnCreateDisabled={false}
            items={accounts}
            create={this.createItem}
            update={this.updateItem}
            delete={this.deleteItem}
            validateRules={validateRules}
            columns={columns}
            actionUpdateShow={(item: any) => !item.processed}
            actionDeleteShow={(item: any) => !item.processed}
            enableReinitialize={true}
            btnCreateShow={false}
            showNewLine={true}
            propsTable = {propsTable}
            pagination = {pagination}
            operationsOnLeft = {true}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  filter: state.account.filter,
  accounts: state.account.list_data,
  total_count: state.account.total_count,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createAccount,
      updateAccount,
      deleteAccount,
      getAccounts,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AccountList);

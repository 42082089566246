import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Spin } from 'antd';
import { AppState } from '../../reducers';
import DictionaryList from './DictionaryList';

class Dictionary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    const {loading} = this.props;
    return (
      <Spin spinning={ loading }>
        <Switch>
          <Route exact path="/dictionary/list" component={DictionaryList}/>
        </Switch>
      </Spin>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  loading: state.dictionary.loading,
});

export default connect(mapStateToProps)(Dictionary);

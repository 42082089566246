import * as React from 'react';
import { Spin } from 'antd';
import { Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import CategoryList from './CategoryList';

function Category({ loading }: { loading: any }) {

  return (
    <Spin spinning={loading}>
      <Switch>
        <Route exact path="/category/list" component={CategoryList}/>
      </Switch>
    </Spin>
  );
}

const mapStateToProps = (state: AppState) => ({
  loading: state.category.loading,
});

export default connect(mapStateToProps)(Category);
